import React, { Component } from 'react'
import { Mutation } from 'react-apollo'
import '../../css/events.css'
import { Button, Container, Dimmer, Header, Icon, Loader, Segment } from 'semantic-ui-react'
import { withAuthenticator } from '../../auth/Authenticator'
import EntrantsList from './EntrantsList'
import BarLoadingCard from '../../components/BarLoadingCard'
import FinishEventMutation from '../../queries/FinishEventMutation'
import LogAttemptMutation from '../../queries/LogAttemptMutation'
import SetLiveStatusMutation from '../../queries/SetLiveStatusMutation'
import { getEvents } from '../../data/Constants'
import { getFederationData } from '../../data/Federations'
import { getDivision, getNextStatus, getTotal } from '../../utils/entrants'
import { getEventType } from '../../utils/events'

class EventOperation extends Component {
	state = {
		entrantModalOpen: false,
	}

	openEntrantModal = entrant => {
		this.setState({
			editingEntrant: entrant,
			entrantModalOpen: true,
		})
	}

	closeEntrantModal = () => {
		const newState = this.state
		newState.entrantModalOpen = false
		delete newState.editingEntrant
		this.setState(newState)
	}

	getCurrentLift = liveStatus => {
		return `${liveStatus.lift}${liveStatus.attempt}`
	}

	getPreviousLift = liveStatus => {
		if (liveStatus.attempt > 1) {
			return `${liveStatus.lift}${liveStatus.attempt - 1}`
		} else {
			return
		}
	}

	handleLiftAttempt = async (activeLifter, currentLift, entrants, event, finishEvent, liveStatus, logAttempt, setLiveStatus, status) => {
		await logAttempt({
			variables: {
				id: liveStatus.lifter,
				lift: liveStatus.lift,
				number: liveStatus.attempt.toString(),
				attempt: {
					weight: activeLifter[currentLift].weight,
					status: status,
				},
			},
		})
		const nextStatus = getNextStatus(currentLift, entrants, liveStatus)
		if (nextStatus !== null) {
			await setLiveStatus({
				variables: {
					id: event.id,
					liveStatus: nextStatus,
				},
			})
			if (nextStatus.status === 'done' && nextStatus.day === event.days) {
				await finishEvent({
					variables: {
						id: event.id,
					},
				})
			}
		}
	}

	render() {
		const { day, entrants, event, isAuthenticatedUser, liveStatus, openEntrantModal } = this.props
		const activeLifter = entrants && entrants.find(entrant => entrant.id === liveStatus.lifter)
		const events = getEvents()

		const { weightClasses } = getFederationData(event.federation)
		if (entrants && entrants.length > 0) {
			entrants.forEach(entrant => {
				entrant.events = getEventType(event, entrant)
				entrant.weightClass = weightClasses.find(weightClass => weightClass.gender === entrant.gender && entrant.weight <= weightClass.max).class
				entrant.max = weightClasses.find(weightClass => weightClass.gender === entrant.gender && entrant.weight <= weightClass.max).max
				entrant.division = getDivision(entrant, event)
			})
			if (liveStatus.status === 'active') {
				entrants.sort((a, b) => {
					if (a.flight !== b.flight) return a.flight < b.flight ? -1 : 1
					if (
						a[this.getCurrentLift(liveStatus)] &&
						b[this.getCurrentLift(liveStatus)] &&
						Math.abs(a[this.getCurrentLift(liveStatus)].weight) !== Math.abs(b[this.getCurrentLift(liveStatus)].weight)
					)
						return Math.abs(a[this.getCurrentLift(liveStatus)].weight) - Math.abs(b[this.getCurrentLift(liveStatus)].weight)
					if (
						a[this.getCurrentLift(liveStatus)] &&
						a[this.getPreviousLift(liveStatus)] &&
						b[this.getCurrentLift(liveStatus)] &&
						b[this.getPreviousLift(liveStatus)] &&
						Math.abs(a[this.getPreviousLift(liveStatus)].weight) !== Math.abs(b[this.getPreviousLift(liveStatus)].weight)
					)
						return Math.abs(a[this.getPreviousLift(liveStatus)].weight) - Math.abs(b[this.getPreviousLift(liveStatus)].weight)

					if (a[this.getCurrentLift(liveStatus)] && !b[this.getCurrentLift(liveStatus)]) {
						return -1
					}

					if (!a[this.getCurrentLift(liveStatus)] && b[this.getCurrentLift(liveStatus)]) {
						return 1
					}
					return a.weight - b.weight
				})
			} else if (liveStatus.status === 'done') {
				entrants.sort((a, b) => {
					if (a.gender !== b.gender) return a.gender < b.gender ? -1 : 1
					if (events.indexOf(a.events) !== events.indexOf(b.events)) return events.indexOf(a.events) - events.indexOf(b.events)
					if (a.equipment !== b.equipment) return a.equipment < b.equipment ? 1 : -1
					if (a.max !== b.max) return a.max - b.max
					if (getTotal(a) && getTotal(b)) return getTotal(b) - getTotal(a)
					if (getTotal(a) && !getTotal(b)) return -1
					if (!getTotal(a) && getTotal(b)) return 1
					return a.weight - b.weight
				})
			} else {
				entrants.sort((a, b) => {
					if (a.flight !== b.flight) return a.flight < b.flight ? -1 : 1
					if (a['squat1'] && b['squat1'] && Math.abs(a['squat1'].weight) !== Math.abs(b['squat1'].weight))
						return Math.abs(a['squat1'].weight) - Math.abs(b['squat1'].weight)
					if (a['squat1'] && !b['squat1']) return -1
					if (!a['squat1'] && b['squat1']) return 1
					return a.weight - b.weight
				})
			}
		}
		return (
			<Mutation mutation={SetLiveStatusMutation} key={event.id + '-setLiveStatus'}>
				{(setLiveStatus, { loading: updating }) => (
					<Container fluid>
						{!updating &&
							isAuthenticatedUser(event.username) &&
							(liveStatus.status === 'ready' || (liveStatus.status === 'done' && liveStatus.day < day && day <= event.days)) && (
								<Segment>
									Start Lifting (Day {day}){' '}
									<Icon
										name="play"
										onClick={async e => {
											e.preventDefault()
											const { __typename, ...noTypenameLiveStatus } = liveStatus
											await setLiveStatus({
												variables: {
													id: event.id,
													liveStatus: {
														...noTypenameLiveStatus,
														day: day,
														status: 'active',
													},
												},
											})
										}}
									/>
								</Segment>
							)}
						{!updating && ['active', 'done'].includes(liveStatus.status) && (
							<Segment basic>
								<Header as="h1" textAlign="center">
									{event.eventName}
									{event.days > 1 ? `  (Day ${liveStatus.day})` : ''}
								</Header>
							</Segment>
						)}
						{liveStatus.status === 'active' && (
							<Dimmer.Dimmable as={Segment.Group} horizontal dimmed={updating}>
								{updating && (
									<Dimmer inverted active={updating}>
										<Loader>Loading</Loader>
									</Dimmer>
								)}
								{activeLifter && (
									<Segment>
										<Segment basic style={{ padding: 0 }}>
											<Header size="huge">{activeLifter.name}</Header>
										</Segment>
										<Segment basic>
											<Segment floated="left" basic>
												<p>Put some stuff here?</p>
											</Segment>
											<Segment floated="right" basic>
												<Header size="huge">
													{activeLifter[`${liveStatus.lift}${liveStatus.attempt}`] &&
														activeLifter[`${liveStatus.lift}${liveStatus.attempt}`].weight}
													kg
												</Header>
											</Segment>
										</Segment>
										{isAuthenticatedUser(event.username) && (
											<Mutation mutation={FinishEventMutation} key={event.id + '-finish'}>
												{(finishEvent, { loading: finishing }) => (
													<Mutation mutation={LogAttemptMutation} key={event.id + '-logAttempt'}>
														{(logAttempt, { loading: logging }) => (
															<Segment basic>
																<Button
																	basic
																	color="green"
																	disabled={finishing || logging || updating}
																	onClick={() => {
																		if (!logging && !updating) {
																			this.handleLiftAttempt(
																				activeLifter,
																				`${liveStatus.lift}${liveStatus.attempt}`,
																				entrants,
																				event,
																				finishEvent,
																				liveStatus,
																				logAttempt,
																				setLiveStatus,
																				'goodlift'
																			)
																		}
																	}}>
																	Good Lift
																</Button>
																<Button
																	basic
																	color="red"
																	disabled={finishing || logging || updating}
																	onClick={async e => {
																		e.preventDefault()
																		if (!logging && !updating) {
																			this.handleLiftAttempt(
																				activeLifter,
																				`${liveStatus.lift}${liveStatus.attempt}`,
																				entrants,
																				event,
																				finishEvent,
																				liveStatus,
																				logAttempt,
																				setLiveStatus,
																				'nolift'
																			)
																		}
																	}}>
																	No Lift
																</Button>
															</Segment>
														)}
													</Mutation>
												)}
											</Mutation>
										)}
									</Segment>
								)}
								{activeLifter && (
									<BarLoadingCard
										lift={liveStatus.lift}
										weight={
											activeLifter[`${liveStatus.lift}${liveStatus.attempt}`]
												? activeLifter[`${liveStatus.lift}${liveStatus.attempt}`].weight
												: 0
										}
									/>
								)}
							</Dimmer.Dimmable>
						)}
						<EntrantsList event={event} entrants={entrants} liveStatus={liveStatus} openEntrantModal={openEntrantModal} />
					</Container>
				)}
			</Mutation>
		)
	}
}

export default withAuthenticator(EventOperation)
