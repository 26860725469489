import React, { Component } from 'react'
import { Button, Form, Grid, Header, Image, Message, Label, Input, Icon } from 'semantic-ui-react'
import shorthash from 'shorthash'
import '../css/login.css'

function querystring(name, url = window.location.href) {
	name = name.replace(/[[]]/g, '\\$&')

	const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)', 'i')
	const results = regex.exec(url)

	if (!results) {
		return ''
	}
	if (!results[0]) {
		return ''
	}

	return decodeURIComponent(results[0].replace(/\+/g, ' '))
}

export default class Login extends Component {
	state = {
		username: this.props.match.params.username ? this.props.match.params.username : '',
		password: '',
		confirmationCode: '',
		cognitoUser: '',
		loading: false,
		confirm: false,
		touched: {
			username: false,
			password: false,
			confirmationCode: false,
		},
		validationErrors: {
			username: '',
			password: '',
			confirmationCode: '',
		},
		error: '',
	}

	signInCustomer = async e => {
		e.preventDefault()
		this.setState({ loading: true })
		try {
			const hash = shorthash.unique(this.state.username.toLowerCase())
			const loggedIn = await this.props.signIn(hash, this.state.password)
			if (!loggedIn) {
				this.setState({
					error: 'Invalid email/phone number or password!',
					loading: false,
				})
			}
		} catch (err) {
			this.setState({
				error: 'Invalid email/phone number or password!',
				loading: false,
			})
		}
	}

	validateField = (name, value) =>
		({
			username: value.length === 0 ? 'Email or phone number cannot by empty!' : '',
			password: value.length > 0 ? '' : 'You need a password!',
			confirmationCode: value.length === 0 ? 'Code cannot by empty!' : '',
		}[name])

	handleChange = (event, { name, value }) => {
		this.setState({
			[name]: value,
		})
	}

	handleBlur = (name, checkUserExists) => async event => {
		this.setState({
			touched: { ...this.state.touched, [name]: true },
			validationErrors: { ...this.state.validationErrors, [name]: this.validateField(name, this.state[name]) },
		})
	}

	handleChangeWithTouch = (event, { name, value }) => {
		this.setState({
			[name]: value,
			touched: { ...this.state.touched, [name]: true },
			validationErrors: { ...this.state.validationErrors, [name]: this.validateField(name, value) },
		})
	}

	shouldMarkError = field => {
		return this.state.touched[field] && (this.state.error !== '' || this.state.validationErrors[field] !== '')
	}

	handleForgot = async e => {
		e.preventDefault()
		var location = '/forgot' + (this.state.username ? '/' + this.state.username : '')
		this.props.history.push(location)
	}

	renderForm() {
		const redirect = querystring('redirect')
		const { username, password, error, validationErrors, loading } = this.state
		const isEnabled = !Object.keys(validationErrors).some(x => validationErrors[x])

		return (
			<div>
				<div className="login-form">
					{/*
              Heads up! The styles below are necessary for the correct render of this example.
              You can do same with CSS, the main idea is that all the elements up to the `Grid`
              below must have a height of 100%.
            */}
					<style>{`
              body > div,
              body > div > div,
              body > div > div > div.login-form {
                height: 100%;
              }
            `}</style>
					<Grid textAlign="center" style={{ height: '100%' }} verticalAlign="middle">
						<Grid.Column style={{ maxWidth: 450 }} textAlign="left">
							<Header as="h2" color="teal" textAlign="center">
								<Image src="/logo.png" />
							</Header>
							<Form size="large" className="attached fluid segment" autoComplete="off">
								<Form.Field error={this.shouldMarkError('username')}>
									{this.shouldMarkError('username') && (
										<Label basic color="red" pointing="below">
											{error ? error : validationErrors.username}
										</Label>
									)}
									<Input
										type="text"
										icon="user"
										iconPosition="left"
										placeholder="Email or phone number"
										name="username"
										focus={true}
										value={username}
										onChange={this.handleChange}
										onBlur={this.handleBlur('username', true)}
									/>
								</Form.Field>
								<Form.Field error={this.shouldMarkError('password')}>
									{this.shouldMarkError('password') && validationErrors.password && (
										<Label basic color="red" pointing="below">
											{validationErrors.password}
										</Label>
									)}
									<Input
										icon={<Icon name="eye" />}
										placeholder="Password"
										iconPosition="left"
										name="password"
										type="password"
										focus={true}
										value={password}
										onChange={this.handleChangeWithTouch}
									/>
								</Form.Field>
								<Button color="teal" loading={loading} disabled={!isEnabled} fluid size="large" onClick={this.signInCustomer}>
									Login
								</Button>
							</Form>
							<Message textalign="center">
								New to us? <a href={'/signup' + redirect}>Sign Up</a>. Forgot you password? Click{' '}
								<a href="/forgot" onClick={this.handleForgot}>
									here
								</a>
							</Message>
						</Grid.Column>
					</Grid>
				</div>
			</div>
		)
	}

	renderConfirmationForm() {
		const { confirmationCode, loading, error, validationErrors } = this.state
		const isEnabled = !(this.state.error || Object.keys(validationErrors).some(x => validationErrors[x]))
		return (
			<div>
				<div className="signup-form">
					<Grid textAlign="center" style={{ height: '100%' }} verticalAlign="middle">
						<Grid.Column style={{ maxWidth: 450 }} textAlign="left">
							<Header as="h2" color="teal" textAlign="center">
								<Image src="/logo.png" />
							</Header>
							<Form size="large" loading={loading} className="attached fluid segment">
								<Form.Field error={this.shouldMarkError('confirmationCode')}>
									{this.shouldMarkError('confirmationCode') && (
										<Label basic color="red" pointing="below">
											{error ? error : validationErrors.confirmationCode}
										</Label>
									)}
									{!this.shouldMarkError('confirmationCode') && (
										<Label basic color="teal" pointing="below">
											Your account isn't confirmed yet, we've sent a code to your email.
										</Label>
									)}
									<Input
										type="text"
										icon="user"
										iconPosition="left"
										placeholder="Confirmation Code"
										name="confirmationCode"
										focus={true}
										value={confirmationCode}
										onBlur={this.handleBlur('confirmationCode')}
										onChange={this.handleChange}
									/>
								</Form.Field>
								<Button.Group fluid size="large" color="teal">
									<Button disabled={!isEnabled} onClick={this.confirmSignup}>
										Confirm
									</Button>
									<Button.Or />
									<Button onClick={this.resendSignup}>Resend</Button>
								</Button.Group>
							</Form>
						</Grid.Column>
					</Grid>
				</div>
			</div>
		)
	}

	render() {
		return <div className="Login">{this.state.confirm ? this.renderConfirmationForm() : this.renderForm()}</div>
	}
}

//export default withApollo(Login)
