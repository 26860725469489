import React, { Component } from 'react'
import { Transition, Image, Card } from 'semantic-ui-react'
import moment from 'moment'
import '../../css/events.css'

export default class EventCards extends Component {
	render() {
		const { events, history } = this.props
		return (
			<Transition.Group as={Card.Group} itemsPerRow={4} stackable duration={2000}>
				{events &&
					events.map(event => {
						return (
							<Card
								key={event.id}
								onClick={() => {
									history.push(`/event/${event.id}`)
								}}>
								<Image
									src={`https://images.powerlifter.pro/${event.image ? event.image : 'placeholder-image-square.jpg'}`}
									wrapped
									ui={false}
									className="squareimg"
								/>
								<Card.Content>
									<Card.Description style={{ color: event.liveStatus ? 'darkgreen' : 'darkorange', fontWeight: 'bold' }}>
										{moment(event.startDateTime * 1000).format('ddd, MMM D')}
										{event.days > 1
											? moment(event.startDateTime * 1000)
													.add(event.days - 1, 'days')
													.format(' - ddd, MMM D')
											: ''}
										{event.liveStatus && event.liveStatus.status === 'active' && ' LIVE!'}
									</Card.Description>
									<Card.Header style={{ paddingTop: '0.5em' }}>{event.eventName}</Card.Header>
									<Card.Description>{event.location}</Card.Description>
								</Card.Content>
							</Card>
						)
					})}
			</Transition.Group>
		)
	}
}
