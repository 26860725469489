const federationsData = {
	gpc: {
		ageCategories: [
			{
				code: 'T1',
				category: 'Teenage',
				min: 13,
				max: 15,
				recordsOnly: false,
				openCategory: false,
			},
			{
				code: 'T2',
				category: 'Teenage',
				min: 16,
				max: 17,
				recordsOnly: false,
				openCategory: false,
			},
			{
				code: 'T3',
				category: 'Teenage',
				min: 18,
				max: 19,
				recordsOnly: false,
				openCategory: false,
			},
			{
				code: 'J',
				category: 'Junior',
				min: 20,
				max: 23,
				recordsOnly: false,
				openCategory: false,
			},
			{
				code: 'O',
				category: 'Open',
				min: 24,
				max: 39,
				recordsOnly: false,
				openCategory: true,
			},
			{
				code: 'S',
				category: 'Sub-Master',
				min: 33,
				max: 39,
				recordsOnly: true,
				openCategory: false,
			},
			{
				code: 'M1',
				category: 'Master',
				min: 40,
				max: 44,
				recordsOnly: false,
				openCategory: false,
			},
			{
				code: 'M2',
				category: 'Master',
				min: 45,
				max: 49,
				recordsOnly: false,
				openCategory: false,
			},
			{
				code: 'M3',
				category: 'Master',
				min: 50,
				max: 54,
				recordsOnly: false,
				openCategory: false,
			},
			{
				code: 'M4',
				category: 'Master',
				min: 55,
				max: 59,
				recordsOnly: false,
				openCategory: false,
			},
			{
				code: 'M5',
				category: 'Master',
				min: 60,
				max: 64,
				recordsOnly: false,
				openCategory: false,
			},
			{
				code: 'M6',
				category: 'Master',
				min: 65,
				max: 69,
				recordsOnly: false,
				openCategory: false,
			},
			{
				code: 'M7',
				category: 'Master',
				min: 70,
				max: 74,
				recordsOnly: false,
				openCategory: false,
			},
			{
				code: 'M8',
				category: 'Master',
				min: 75,
				max: 79,
				recordsOnly: false,
				openCategory: false,
			},
			{
				code: 'M9',
				category: 'Master',
				min: 80,
				max: 999,
				recordsOnly: false,
				openCategory: false,
			},
		],
		divisions: {
			powerlifting: 'owt',
			pushpull: 'owt',
			benchonly: 'owt',
			deadliftonly: 'owt',
		},
		equipmentOptions: ['R', 'E'],
		fourthAttempts: true,
		weightClasses: [
			{
				gender: 'F',
				class: '44',
				max: 44,
			},
			{
				gender: 'F',
				class: '48',
				max: 48,
			},
			{
				gender: 'F',
				class: '52',
				max: 52,
			},
			{
				gender: 'F',
				class: '56',
				max: 56,
			},
			{
				gender: 'F',
				class: '60',
				max: 60,
			},
			{
				gender: 'F',
				class: '67.5',
				max: 67.5,
			},
			{
				gender: 'F',
				class: '75',
				max: 75,
			},
			{
				gender: 'F',
				class: '82.5',
				max: 82.5,
			},
			{
				gender: 'F',
				class: '90',
				max: 90,
			},
			{
				gender: 'F',
				class: '110',
				max: 110,
			},
			{
				gender: 'F',
				class: '110+',
				max: 999,
			},
			{
				gender: 'M',
				class: '56',
				max: 56,
			},
			{
				gender: 'M',
				class: '60',
				max: 60,
			},
			{
				gender: 'M',
				class: '67.5',
				max: 67.5,
			},
			{
				gender: 'M',
				class: '75',
				max: 75,
			},
			{
				gender: 'M',
				class: '82.5',
				max: 82.5,
			},
			{
				gender: 'M',
				class: '90',
				max: 90,
			},
			{
				gender: 'M',
				class: '100',
				max: 100,
			},
			{
				gender: 'M',
				class: '110',
				max: 110,
			},
			{
				gender: 'M',
				class: '125',
				max: 125,
			},
			{
				gender: 'M',
				class: '140',
				max: 140,
			},
			{
				gender: 'M',
				class: '140+',
				max: 999,
			},
		],
		weighIn: 24,
	},
	proraw: {
		ageCategories: [
			{
				code: 'O',
				category: 'Open',
				min: 20,
				max: 999,
				recordsOnly: false,
				openCategory: true,
			},
		],
		divisions: {
			powerlifting: 'owt',
			benchonly: 'of',
			deadliftonly: 'of',
		},
		equipmentOptions: ['R'],
		fourthAttempts: false,
		weightClasses: [
			{
				gender: 'F',
				class: '60',
				max: 60,
			},
			{
				gender: 'F',
				class: '75',
				max: 75,
			},
			{
				gender: 'F',
				class: '75+',
				max: 999,
			},
			{
				gender: 'M',
				class: '80',
				max: 80,
			},
			{
				gender: 'M',
				class: '95',
				max: 95,
			},
			{
				gender: 'M',
				class: '110',
				max: 110,
			},
			{
				gender: 'M',
				class: '125',
				max: 125,
			},
			{
				gender: 'M',
				class: '125+',
				max: 999,
			},
		],
		weighIn: 2,
	},
	wrpf: {
		ageCategories: [
			{
				code: 'Y1',
				category: 'Youth',
				min: 14,
				max: 16,
				recordsOnly: false,
				openCategory: false,
			},
			{
				code: 'Y2',
				category: 'Youth',
				min: 17,
				max: 19,
				recordsOnly: false,
				openCategory: false,
			},
			{
				code: 'J',
				category: 'Junior',
				min: 20,
				max: 23,
				recordsOnly: false,
				openCategory: false,
			},
			{
				code: 'O',
				category: 'Open',
				min: 24,
				max: 39,
				recordsOnly: false,
				openCategory: true,
			},
			{
				code: 'M1',
				category: 'Master',
				min: 40,
				max: 49,
				recordsOnly: false,
				openCategory: false,
			},
			{
				code: 'M2',
				category: 'Master',
				min: 50,
				max: 59,
				recordsOnly: false,
				openCategory: false,
			},
			{
				code: 'M3',
				category: 'Master',
				min: 60,
				max: 69,
				recordsOnly: false,
				openCategory: false,
			},
			{
				code: 'M4',
				category: 'Master',
				min: 70,
				max: 79,
				recordsOnly: false,
				openCategory: false,
			},
			{
				code: 'M5',
				category: 'Master',
				min: 80,
				max: 999,
				recordsOnly: false,
				openCategory: false,
			},
		],
		divisions: {
			powerlifting: 'owt',
			pushpull: 'owt',
			benchonly: 'owt',
			deadliftonly: 'owt',
		},
		equipmentOptions: ['R', 'E'],
		fourthAttempts: true,
		weightClasses: [
			{
				gender: 'F',
				class: '44',
				max: 44,
			},
			{
				gender: 'F',
				class: '48',
				max: 48,
			},
			{
				gender: 'F',
				class: '52',
				max: 52,
			},
			{
				gender: 'F',
				class: '56',
				max: 56,
			},
			{
				gender: 'F',
				class: '60',
				max: 60,
			},
			{
				gender: 'F',
				class: '67.5',
				max: 67.5,
			},
			{
				gender: 'F',
				class: '75',
				max: 75,
			},
			{
				gender: 'F',
				class: '82.5',
				max: 82.5,
			},
			{
				gender: 'F',
				class: '90+',
				max: 999,
			},
			{
				gender: 'M',
				class: '56',
				max: 56,
			},
			{
				gender: 'M',
				class: '60',
				max: 60,
			},
			{
				gender: 'M',
				class: '67.5',
				max: 67.5,
			},
			{
				gender: 'M',
				class: '75',
				max: 75,
			},
			{
				gender: 'M',
				class: '82.5',
				max: 82.5,
			},
			{
				gender: 'M',
				class: '90',
				max: 90,
			},
			{
				gender: 'M',
				class: '100',
				max: 100,
			},
			{
				gender: 'M',
				class: '110',
				max: 110,
			},
			{
				gender: 'M',
				class: '125',
				max: 125,
			},
			{
				gender: 'M',
				class: '140',
				max: 140,
			},
			{
				gender: 'M',
				class: '140+',
				max: 999,
			},
		],
		weighIn: 24,
	},
}

export function getFederationOptions() {
	return [{ key: 'gpc', text: 'GPC', value: 'gpc' }, { key: 'proraw', text: 'ProRaw', value: 'proraw' }, { key: 'wrpf', text: 'WRPF', value: 'wrpf' }]
}

export function getFederationData(fed) {
	if (Object.keys(federationsData).includes(fed)) {
		return federationsData[fed]
	}
	return undefined
}
