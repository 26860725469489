const pairPlates = {
	'25kgplate': 50,
	'20kgplate': 40,
	'15kgplate': 30,
	'10kgplate': 20,
	'5kgplate': 10,
	'2point5kgplate': 5,
	'1point25kgplate': 2.5,
	'0point5kgplate': 1,
}

export const getPlates = plateWeight => {
	const plates = []
	let count = 0
	while (plateWeight > 0 && count < 20) {
		const plateKeys = Object.keys(pairPlates)
		let index = 0
		let pair = ''
		while (plateWeight > 0 && (!pair || index < plateKeys.length)) {
			if (plateWeight - pairPlates[plateKeys[index]] >= 0) {
				pair = plateKeys[index]
			} else {
				pair = ''
				++index
			}
			if (pair) {
				plates.push(pair)
				plateWeight -= pairPlates[pair]
			}
		}
		++count
	}
	return plates
}
