import React, { Component } from 'react'
import { withMenu } from '../containers/Menu'

class Home extends Component {
	state = {
		menuProps: {
			style: {
				backgroundColor: '#fff',
				border: 'none',
				borderRadius: 0,
				boxShadow: 'none',
				padding: '10px 0',
				borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
			},
		},
		menuOptionsProps: {
			style: {
				display: 'flex',
			},
		},
		hamburgerMenuProps: {
			style: {
				display: 'none',
			},
		},
	}

	componentDidMount = async () => {}

	render() {
		return (
			<div>
				<h1>PowerlifterPro</h1>
				<p>Put stuff here</p>
			</div>
		)
	}
}

export default withMenu(Home)
