import { Component } from 'react'

export default class EventSubscriber extends Component {
	constructor(props) {
		super(props)
		this.props.subscribeToEntrantChanges()
		this.props.subscribeToEventChanges()
	}

	render = () => {
		return null
	}
}
