import gql from 'graphql-tag'

export default gql(`
query GetEntrants($event: String!) {
	entrants(event: $event) {
		__typename
		id
		event
		status
		name
		age
		gender
		weight
		equipment
		rackHeight
		racksInOut
		benchHeight
		faceSaverHeight
		squat1 {
			status
			weight
		}
		squat2 {
			status
			weight
		}
		squat3 {
			status
			weight
		}
		squat4 {
			status
			weight
		}
		bench1 {
			status
			weight
		}
		bench2 {
			status
			weight
		}
		bench3 {
			status
			weight
		}
		bench4 {
			status
			weight
		}
		deadlift1 {
			status
			weight
		}
		deadlift2 {
			status
			weight
		}
		deadlift3 {
			status
			weight
		}
		deadlift4 {
			status
			weight
		}
		flight
		day
	}
}`)
