import React, { Component } from 'react'
import { Mutation } from 'react-apollo'
import { Grid, Progress } from 'semantic-ui-react'
import '../css/createevent.css'
import CreateEventInfo from './components/CreateEventInfo'
import CreateEventSetup from './components/CreateEventSetup'
import CreateEventDivisions from './components/CreateEventDivisions'
import CreateEventConfiguration from './components/CreateEventConfiguration'
import CreateEventMutation from '../queries/CreateEventMutation'
import { withMenu } from '../containers/Menu'

const stepName = {
	1: 'Create Event',
	2: 'Choose Event Defaults',
	3: 'Set Event Configuration',
	4: 'Set Event Divisions',
	5: 'Finalize',
}

class CreateEvent extends Component {
	constructor(props) {
		super(props)
		this.state = this.getInitialState(props.userData.username)
	}

	static defaultProps = {
		createEvent: () => null,
	}

	getInitialState = username => ({
		step: 1,
		event: {
			username: username,
			eventName: '',
			eventStatus: 'active',
			startDateTime: null,
			days: 1,
			weighIn: 2,
			capacity: 0,
			placeId: '',
			location: '',
			federation: '',
			equipmentOptions: [],
			weightClasses: [],
			divisions: {},
		},
		location: {
			address: '',
		},
	})

	nextStep = stepChange => {
		this.applyStepChange(stepChange, 1)
	}

	stepBack = stepChange => {
		this.applyStepChange(stepChange, -1)
	}

	applyStepChange = (stepChange, adjustStep) => {
		const newState = {
			event: {
				...this.state.event,
				...stepChange.event,
			},
			step: this.state.step + adjustStep,
		}
		if (stepChange.image) {
			newState.image = stepChange.image
		}
		if (stepChange.location) {
			newState.location = stepChange.location
		}
		this.setState(newState)
	}

	render() {
		const { event, image, location, step } = this.state
		const { history } = this.props

		return (
			<div className="CreateEvent">
				<div className="create-event-form">
					<Mutation mutation={CreateEventMutation}>
						{(createEvent, { loading: creating, error }) => (
							<Grid textAlign="center" style={{ height: '100%' }} verticalAlign="middle">
								<Grid.Column style={{ maxWidth: 600 }} textAlign="left">
									<Progress value={step} total="5" progress="ratio" color="orange">
										{stepName[step]}
									</Progress>
									{step === 1 && <CreateEventInfo event={event} image={image} location={location} nextStep={this.nextStep} />}
									{step === 2 && <CreateEventConfiguration event={event} nextStep={this.nextStep} stepBack={this.stepBack} />}
									{step === 3 && <CreateEventSetup event={event} nextStep={this.nextStep} stepBack={this.stepBack} />}
									{step === 4 && (
										<CreateEventDivisions
											event={event}
											image={image}
											createEvent={createEvent}
											history={history}
											nextStep={this.nextStep}
											stepBack={this.stepBack}
										/>
									)}
								</Grid.Column>
							</Grid>
						)}
					</Mutation>
				</div>
			</div>
		)
	}
}

export default withMenu(CreateEvent)
