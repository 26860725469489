import { getEventsMapping, getFirstFlight, getNextFlight, getNextLift } from '../data/Constants'
import { getFederationData } from '../data/Federations'

export const getBestSquat = entrant => {
	return ['1', '2', '3'].reduce((bestlift, attempt) => {
		return entrant[`squat${attempt}`] && entrant[`squat${attempt}`].status === 'goodlift' ? entrant[`squat${attempt}`].weight : bestlift
	}, '')
}

export const getBestBench = entrant => {
	return ['1', '2', '3'].reduce((bestlift, attempt) => {
		return entrant[`bench${attempt}`] && entrant[`bench${attempt}`].status === 'goodlift' ? entrant[`bench${attempt}`].weight : bestlift
	}, '')
}

export const getSubTotal = entrant => {
	const bestSquat = getBestSquat(entrant)
	const bestBench = getBestBench(entrant)
	return bestSquat && bestBench ? bestSquat + bestBench : ''
}

export const getBestDeadlift = entrant => {
	return ['1', '2', '3'].reduce((bestlift, attempt) => {
		return entrant[`deadlift${attempt}`] && entrant[`deadlift${attempt}`].status === 'goodlift' ? entrant[`deadlift${attempt}`].weight : bestlift
	}, '')
}

export const getTotal = entrant => {
	if (entrant.events === 'benchonly') return getBestBench(entrant)
	if (entrant.events === 'deadliftonly') return getBestDeadlift(entrant)
	if (entrant.events === 'deadliftonly') return getBestBench(entrant) && getBestDeadlift(entrant) ? getBestBench(entrant) + getBestDeadlift(entrant) : ''
	const subTotal = getSubTotal(entrant)
	const bestDeadlift = getBestDeadlift(entrant)
	return subTotal && bestDeadlift ? subTotal + bestDeadlift : ''
}

export const getResult = (entrant, entrants, event) => {
	//Only doing awt, owt for powerlifting, do others
	const rivals = entrants.filter(
		rival => rival.events === entrant.events && rival.division === entrant.division && rival.weightClass === entrant.weightClass && getTotal(rival)
	)

	rivals.sort((a, b) => {
		if (getTotal(a) && getTotal(b) && getTotal(a) !== getTotal(b)) return getTotal(b) - getTotal(a)
		return a.weight - b.weight
	})
	const position = rivals.findIndex(rival => rival.id === entrant.id) + 1
	return position
		? `${position}-${entrant.division}-${entrant.weightClass}${entrant.events !== 'powerlifting' ? `-${getEventsMapping(entrant.events)}` : ''}`
		: ''
}

export const getDivision = (entrant, event) => {
	const { ageCategories } = getFederationData(event.federation)
	const ageCode =
		entrant.events !== 'error' && event.divisions[entrant.events].startsWith('a')
			? ageCategories.find(ageCat => entrant.age >= ageCat.min && entrant.age <= ageCat.max && !ageCat.recordsOnly).code
			: ageCategories.find(ageCat => ageCat.openCategory).code
	return `${entrant.gender}-${ageCode}${entrant.equipment}`
}

export const getNextStatus = (currentLift, entrants, liveStatus) => {
	const { __typename, ...nextStatus } = liveStatus
	const nextLifter = entrants[entrants.findIndex(entrant => entrant.id === liveStatus.lifter) + 1]
	console.log('nextlifter', nextLifter)
	if (
		nextLifter &&
		nextLifter[currentLift] &&
		nextLifter[currentLift].status === 'notattempted' &&
		nextLifter[currentLift].weight &&
		nextLifter.flight === liveStatus.flight
	) {
		nextStatus.lifter = nextLifter.id
	} else {
		const nextLift = `${liveStatus.lift}${liveStatus.attempt + 1}`
		const resortedEntrants = [...entrants]
		resortedEntrants.sort((a, b) => {
			if (a.flight !== b.flight) return a.flight < b.flight ? -1 : 1
			if (a[nextLift] && b[nextLift] && a[nextLift].weight !== b[nextLift].weight) return a[nextLift].weight - b[nextLift].weight
			if (a[nextLift] && !b[nextLift]) {
				return -1
			}
			if (!a[nextLift] && b[nextLift]) {
				return 1
			}
			return a.weight - b.weight
		})
		//check if we can move to the next attempt in the current flight
		var firstLifter = resortedEntrants.reduce((currentNextLifter, lifter) => {
			return lifter[nextLift] &&
				lifter[nextLift].status === 'notattempted' &&
				lifter[nextLift].weight < currentNextLifter[nextLift].weight &&
				lifter.flight === liveStatus.flight
				? lifter
				: currentNextLifter
		}, resortedEntrants.find(entrant => entrant[nextLift] && entrant[nextLift].status === 'notattempted' && entrant.flight === liveStatus.flight))
		if (firstLifter) {
			nextStatus.lifter = firstLifter.id
			nextStatus.attempt = liveStatus.attempt + 1
		} else {
			const nextLift = `${liveStatus.lift}1`
			const nextFlight = getNextFlight(liveStatus.flight).value
			const nextFlightEntrants = [...entrants]
			nextFlightEntrants.sort((a, b) => {
				if (a.flight !== b.flight) return a.flight < b.flight ? -1 : 1
				if (a[nextLift] && b[nextLift] && a[nextLift].weight !== b[nextLift].weight) return a[nextLift].weight - b[nextLift].weight
				if (a[nextLift] && !b[nextLift]) {
					return -1
				}
				if (!a[nextLift] && b[nextLift]) {
					return 1
				}
				return a.weight - b.weight
			})
			firstLifter = nextFlightEntrants.find(entrant => entrant[nextLift] && entrant[nextLift].status === 'notattempted' && entrant.flight === nextFlight)
			if (firstLifter) {
				nextStatus.lifter = firstLifter.id
				nextStatus.flight = nextFlight
				nextStatus.attempt = 1
			} else {
				// try next lift?
				const nextLift = `${getNextLift(liveStatus.lift)}1`
				const nextFlight = getFirstFlight().value
				const nextLiftEntrants = [...entrants]
				nextLiftEntrants.sort((a, b) => {
					if (a.flight !== b.flight) return a.flight < b.flight ? -1 : 1
					if (a[nextLift] && b[nextLift] && a[nextLift].weight !== b[nextLift].weight) return a[nextLift].weight - b[nextLift].weight
					if (a[nextLift] && !b[nextLift]) {
						return -1
					}
					if (!a[nextLift] && b[nextLift]) {
						return 1
					}
					return a.weight - b.weight
				})

				firstLifter = nextLiftEntrants.find(
					entrant => entrant[nextLift] && entrant[nextLift].status === 'notattempted' && entrant.flight === nextFlight
				)
				if (firstLifter) {
					nextStatus.lifter = firstLifter.id
					nextStatus.lift = getNextLift(liveStatus.lift)
					nextStatus.flight = nextFlight
					nextStatus.attempt = 1
				} else {
					//We all done then?
					nextStatus.status = 'done'
				}
			}
		}
	}
	console.log(nextStatus)
	return nextStatus
}
