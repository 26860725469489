import React, { Component } from 'react'
import { Query } from 'react-apollo'
import '../css/events.css'
import { Container, Grid, Header, Image, List, Loader, Transition } from 'semantic-ui-react'
import { withMenu } from '../containers/Menu'
import AllEvents from '../queries/AllEvents'
//import EventsChangeSubscription from '../queries/EventsChangeSubscription'
import moment from 'moment'

class Results extends Component {
	render = () => {
		return (
			<Query query={AllEvents} variables={{ eventStatus: 'done' }} fetchPolicy="cache-and-network">
				{({ data, error, loading, refetch, subscribeToMore }) => {
					if (error) {
						return <Container>{error}</Container>
					}
					return (
						<Container>
							{!loading && (!data || !data.allEvents || data.allEvents.length === 0) && (
								<span>
									<br />
									No event results found.
									<br />
								</span>
							)}
							{loading && (!data || !data.allEvents || data.allEvents.length === 0) && <Loader active>Loading Events</Loader>}
							{data && data.allEvents && data.allEvents.length > 0 && (
								//basic="very"
								<Transition.Group as={List} divided relaxed duration={2000}>
									{data.allEvents
										.sort((ev1, ev2) => ev2.startDateTime - ev1.startDateTime)
										.map(event => (
											<List.Item
												key={event.id}
												style={{ cursor: 'pointer' }}
												onClick={() => {
													this.props.history.push(
														`/${
															this.props.authenticated && event.username === this.props.userData.username ? 'userEvent' : 'result'
														}/${event.id}`
													)
												}}>
												<Grid container>
													<Grid.Row>
														<Grid.Column width={1} verticalAlign="top" style={{ paddingRight: 0 }}>
															<Image
																style={{ marginRight: '0px', marginLeft: 'auto' }}
																className="squareimg"
																size="tiny"
																src={`https://images.powerlifter.pro/${
																	event.image ? event.image : 'placeholder-image-square.jpg'
																}`}
															/>
														</Grid.Column>
														<Grid.Column textAlign="center" width={1} verticalAlign="top">
															<Header
																style={{
																	marginBottom: '0px',
																	fontSize: '24px',
																	lineHeight: '24px',
																	color: 'rgba(0,0,0,0.8)',
																}}>
																{moment(event.startDateTime * 1000).format('D')}
															</Header>
															<span
																style={{
																	color: 'rgba(0,0,0,0.5)',
																}}>
																{moment(event.startDateTime * 1000)
																	.format('MMM')
																	.toUpperCase()}
															</span>
														</Grid.Column>
														<Grid.Column width={13} verticalAlign="top" style={{ paddingLeft: 0 }}>
															<Header
																style={{
																	marginBottom: '0px',
																	color: 'rgba(0,0,0,0.8)',
																}}>
																{event.eventName}
															</Header>
															<span
																style={{
																	color: 'rgba(0,0,0,0.5)',
																}}>
																{event.location}
															</span>
														</Grid.Column>
													</Grid.Row>
												</Grid>
											</List.Item>
										))}
								</Transition.Group>
							)}
						</Container>
					)
				}}
			</Query>
		)
	}
}

export default withMenu(Results)
