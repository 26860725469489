import React, { Component } from 'react'
import { Transition, Header, Grid, Image, List } from 'semantic-ui-react'
import moment from 'moment'
import '../../css/events.css'

export default class EventList extends Component {
	render() {
		const { events, history } = this.props
		return (
			<Transition.Group as={List} divided relaxed duration={2000}>
				{events.map(event => (
					<List.Item
						key={event.id}
						style={{ cursor: 'pointer' }}
						onClick={() => {
							history.push(`/event/${event.id}`)
						}}>
						<Grid>
							<Grid.Row>
								<Grid.Column textAlign="center" width={1} verticalAlign="top">
									<Header
										style={{
											marginBottom: '0px',
											fontSize: '24px',
											lineHeight: '24px',
											color: 'rgba(0,0,0,0.8)',
										}}>
										{moment(event.startDateTime * 1000).format('D')}
									</Header>
									<span
										style={{
											color: 'rgba(0,0,0,0.5)',
										}}>
										{moment(event.startDateTime * 1000)
											.format('MMM')
											.toUpperCase()}
									</span>
								</Grid.Column>
								<Grid.Column width={13} verticalAlign="top">
									<Header
										style={{
											marginBottom: '0px',
											color: 'rgba(0,0,0,0.8)',
										}}>
										{event.eventName}
									</Header>
									<span
										style={{
											color: 'rgba(0,0,0,0.5)',
										}}>
										{event.location}
									</span>
								</Grid.Column>
								<Grid.Column width={2} verticalAlign="top">
									<Image
										style={{ marginRight: '0px', marginLeft: 'auto' }}
										className="squareimg"
										size="tiny"
										src={`https://images.powerlifter.pro/${event.image ? event.image : 'placeholder-image-square.jpg'}`}
									/>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</List.Item>
				))}
			</Transition.Group>
		)
	}
}
