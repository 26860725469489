import React, { Component } from 'react'
import EventCards from './EventCards'
import EventList from './EventList'

export default class EventItems extends Component {
	constructor(props) {
		super(props)
		const { loading, refetch, setLoading, setRefetch, subscribeToEventChanges } = this.props
		subscribeToEventChanges()
		setLoading(loading)
		setRefetch(refetch)
	}

	render() {
		const { events, history, viewMode } = this.props
		if (viewMode === 'card') return <EventCards events={events} history={history} />
		if (viewMode === 'list') return <EventList events={events} history={history} />
	}
}
