import React from 'react'
import { Storage } from 'aws-amplify'
import { Button, Grid, Form, Header, Icon } from 'semantic-ui-react'
import mime from 'mime-types'
import { v4 as uuid } from 'uuid'
import '../../css/createevent.css'

const eventTypes = {
	powerlifting: 'Powerlifting',
	pushpull: 'Push/Pull',
	benchonly: 'Bench Only',
	deadliftonly: 'Deadlift Only',
}

const divisionOptions = [
	{ key: 'awt', text: 'Age Category / Weight Class by Total', value: 'awt' },
	{ key: 'owt', text: 'Open / Weight Class by Total', value: 'owt' },
	{ key: 'of', text: 'Open by Formula', value: 'of' },
	{ key: 'ot', text: 'Open by Total', value: 'ot' },
]

export default class CreateEventDivisions extends React.Component {
	constructor(props) {
		super(props)
		this.state = this.getInitialState({ ...props.event })
		Storage.configure({ level: 'public', bucket: 'images.powerlifter.pro' })
	}

	getInitialState = event => ({ event })

	changeDivision = (e, { name, value }) => {
		const { event } = this.state

		event.divisions[name.split('-')[0]] = value

		this.setState({ event })
	}

	render() {
		const { createEvent, history, image, stepBack } = this.props
		const { event } = this.state
		const isEnabled = Object.keys(event.divisions).reduce((enabled, option) => {
			return enabled && event.divisions[option] !== ''
		}, true)

		return (
			<Form size="large" className="attached fluid" autoComplete="off">
				<Grid>
					<Grid.Row>
						<Grid.Column>
							<Header as="h4">Divisions</Header>
							{Object.keys(eventTypes)
								.filter(type => Object.keys(event.divisions).includes(type))
								.map(option => (
									<Form.Select
										key={option}
										inline
										label={eventTypes[option]}
										placeholder="Chose a division scheme"
										fluid
										selection
										options={divisionOptions}
										name={option + '-division-'}
										value={event.divisions[option]}
										onChange={this.changeDivision}
									/>
								))}
							<Button
								animated
								color="orange"
								disabled={!isEnabled}
								floated="right"
								size="large"
								onClick={async e => {
									e.preventDefault()
									if (typeof image !== 'undefined') {
										const filename = `${uuid()}.${mime.extension(image.type)}`
										const { key } = await Storage.put(filename, image.file, {
											contentType: image.type,
										})
										event.image = key
									}
									console.log(event)
									await createEvent({
										variables: event,
									})
									history.push('/userEvents')
									//nextStep({ event })
								}}>
								<Button.Content visible>Next</Button.Content>
								<Button.Content hidden>
									<Icon name="arrow right" />
								</Button.Content>
							</Button>
							<Button
								animated
								color="grey"
								floated="left"
								size="large"
								onClick={async e => {
									e.preventDefault()
									stepBack({ event })
								}}>
								<Button.Content visible>Back</Button.Content>
								<Button.Content hidden>
									<Icon name="arrow left" />
								</Button.Content>
							</Button>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Form>
		)
	}
}
