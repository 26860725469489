import React from 'react'
import { Button, List, Form, Label, Header, Icon, Container, Select, Grid } from 'semantic-ui-react'
import { getFederationData, getFederationOptions } from '../../data/Federations'
import '../../css/createevent.css'

const federationOptions = getFederationOptions()
const genderConfig = { F: 'Women', M: 'Men' }

export default class CreateEventConfiguration extends React.Component {
	constructor(props) {
		super(props)
		this.state = this.getInitialState(props.event, props.options)
	}

	getInitialState = event => ({
		event: {
			federation: event.federation ? event.federation : '',
		},
		federationData: event.federation ? getFederationData(event.federation) : undefined,
	})

	changeFederation = (e, { name, value }) => {
		const { event } = this.state

		const federationData = getFederationData(value)
		event.federation = value
		event.weighIn = federationData.weighIn
		event.equipmentOptions = [...federationData.equipmentOptions]
		event.divisions = { ...federationData.divisions }

		this.setState({ event, federationData })
	}

	render() {
		const { nextStep, stepBack } = this.props
		const { event, federationData } = this.state
		const isEnabled = typeof event.federation !== 'undefined'

		return (
			<Form size="large" className="attached fluid" autoComplete="off">
				<Grid>
					<Grid.Row>
						<Grid.Column>
							<Form.Field
								control={Select}
								placeholder="Choose a federation (defaults)"
								label="Federation"
								fluid
								search
								selection
								options={federationOptions}
								name="ageCategoryOption"
								value={event.federation}
								onChange={this.changeFederation}
							/>
							{event.federation && (
								<Form.Field key="weightClasses">
									<Header as="h4">Weight Classes</Header>
									{Object.keys(genderConfig).map(gender => {
										const genderWeightClasses = federationData.weightClasses.filter(weightClass => weightClass.gender === gender)
										return (
											<Container as={List} fluid bulleted horizontal key={gender}>
												<List.Item>
													<Icon name={gender === 'F' ? 'female' : 'male'} color={gender === 'F' ? 'pink' : 'blue'} />
												</List.Item>
												{genderWeightClasses
													.sort((wc1, wc2) => {
														return wc1.max - wc2.max
													})
													.map(weightClass => {
														return <List.Item key={gender + weightClass.class}>{weightClass.class}</List.Item>
													})}
											</Container>
										)
									})}
								</Form.Field>
							)}

							{event.federation && (
								<Form.Field key="ageCategories">
									<Header as="h4">Age Categories</Header>
									<Container as={List} fluid bulleted horizontal>
										{federationData.ageCategories
											.sort((wc1, wc2) => {
												return wc1.max - wc2.max
											})
											.map(ageCategory => {
												return (
													<List.Item key={ageCategory + ageCategory.code}>
														{ageCategory.code}
														{' ('}
														{ageCategory.min}
														{ageCategory.max === 999 ? '+' : '-' + ageCategory.max}
														{ageCategory.recordsOnly ? ')* ' : ') '}
													</List.Item>
												)
											})}
									</Container>
								</Form.Field>
							)}
							{event.foo && (
								<Form.Field>
									{event.ageCategories.length > 0 &&
										event.ageCategories
											.sort((wc1, wc2) => {
												return wc1.max - wc2.max
											})
											.map(ageCategory => {
												return (
													<Label basic key={ageCategory.code}>
														{ageCategory.category} ({ageCategory.min}
														{ageCategory.max === 999 ? ' and over' : ' to ' + ageCategory.max})
													</Label>
												)
											})}
								</Form.Field>
							)}
							<Button
								animated
								color="orange"
								disabled={!isEnabled}
								floated="right"
								size="large"
								onClick={async e => {
									e.preventDefault()
									nextStep({ event })
								}}>
								<Button.Content visible>Next</Button.Content>
								<Button.Content hidden>
									<Icon name="arrow right" />
								</Button.Content>
							</Button>
							<Button
								animated
								color="grey"
								floated="left"
								size="large"
								onClick={async e => {
									e.preventDefault()
									stepBack({ event })
								}}>
								<Button.Content visible>Back</Button.Content>
								<Button.Content hidden>
									<Icon name="arrow left" />
								</Button.Content>
							</Button>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Form>
		)
	}
}
