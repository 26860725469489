import React, { Component } from 'react'
import { Segment } from 'semantic-ui-react'
import { getPlates } from '../utils/plateCalculator'

const barWeight = {
	squat: 25,
	bench: 20,
	deadlift: 20,
}

const collars = 5

export default class BarLoadingCard extends Component {
	constructor(props) {
		super(props)
		this.state = this.getInitialState(this.props.weight, this.props.lift)
	}

	getInitialState = (weight, lift) => ({
		lift: lift,
		weight: weight,
	})

	componentDidUpdate = prevProps => {
		if (this.props.weight !== prevProps.weight || this.props.lift !== prevProps.lift) {
			this.setState(this.getInitialState(this.props.weight, this.props.lift))
		}
	}

	render() {
		const { lift, weight } = this.state
		let plateWeight = weight - barWeight[lift] - collars
		const plates = getPlates(plateWeight)
		return (
			<Segment>
				{plates.map((plate, index) => {
					return <img key={`plate${index}`} style={{ height: '200px' }} alt={plate} src={`/${plate}.png`} />
				})}
			</Segment>
		)
	}
}
