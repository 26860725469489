import gql from 'graphql-tag'

export default gql(`
mutation($username: String!, $eventName: String!, $eventStatus: String!, $placeId: String!, $location: String!,
	 $startDateTime: Int!, $days: Int!, $weighIn: Int!, $capacity: Int!, $notes: String, $equipmentOptions: [String], $divisions: DivisionsInput!, $federation: String!, $image: String) {
  createEvent(
	username: $username
	eventName: $eventName
	eventStatus: $eventStatus
	placeId: $placeId
	location: $location
	startDateTime: $startDateTime
	days: $days
	weighIn: $weighIn
	equipmentOptions: $equipmentOptions
	capacity: $capacity
	notes: $notes
	divisions: $divisions
	federation: $federation
	image: $image
  ) {
	  __typename
	id
	username
	eventName
	eventStatus
	placeId
	location
	startDateTime
	days
	weighIn
	capacity
	notes
	equipmentOptions
	divisions {
		powerlifting
		pushpull
		benchonly
		deadliftonly
	}
	federation
	image
  }
}`)
