import React, { Component } from 'react'
import '../css/events.css'
import moment from 'moment'
import { Mutation, Query } from 'react-apollo'
import { Link } from 'react-router-dom'
import { Button, Breadcrumb, Divider, Icon, Loader, Container, Popup, Segment } from 'semantic-ui-react'
import EventSubscriber from './components/EventSubscriber'
import EventRegistration from './components/EventRegistration'
import EventOperation from './components/EventOperation'
import { withMenu } from '../containers/Menu'
import EntrantModal from '../modal/EntrantModal'
import GetEvent from '../queries/GetEvent'
import GetEntrants from '../queries/GetEntrants'
import SetLiveStatusMutation from '../queries/SetLiveStatusMutation'
import EntrantChangeSubscription from '../queries/EntrantChangeSubscription'
import EventChangeSubscription from '../queries/EventChangeSubscription'

class Event extends Component {
	state = {
		entrantModalOpen: false,
	}

	handleRefresh = () => {
		this.state.refetch()
	}

	setRefetch = refetch => {
		this.setState({
			refetch: refetch,
		})
	}

	openEntrantModal = entrant => {
		this.setState({
			editingEntrant: entrant,
			entrantModalOpen: true,
		})
	}

	closeEntrantModal = () => {
		const newState = this.state
		newState.entrantModalOpen = false
		delete newState.editingEntrant
		this.setState(newState)
	}

	render() {
		const { editingEntrant, entrantModalOpen } = this.state
		const { isAuthenticatedUser } = this.props
		return (
			<Query query={GetEvent} variables={{ id: this.props.match.params.event }} fetchPolicy="cache-and-network">
				{({ data, error, loading, subscribeToMore: subscribeToEvent }) => {
					if (error) {
						console.log(error)
						return (
							<Container>
								<Segment inverted color="red" tertiary>
									<pre>
										Bad:{' '}
										{error.graphQLErrors.map(({ message }, i) => (
											<span key={i}>{message}</span>
										))}
									</pre>
								</Segment>
							</Container>
						)
					}
					if (loading && (!data || !data.event)) {
						return (
							<Container>
								<Loader active>Loading Event</Loader>
							</Container>
						)
					}
					const { event } = data
					const day = Math.ceil(moment().diff(moment(event.startDateTime * 1000), 'days', true))
					return (
						<Query query={GetEntrants} variables={{ event: event.id }} fetchPolicy="cache-and-network">
							{({ data: entrantsData, error: entrantsError, loading: loadingEntrants, refetch, subscribeToMore: subscribeToEntrants }) => {
								if (entrantsError) {
									console.log(entrantsError)
									return (
										<Container>
											<Segment inverted color="red" tertiary>
												<pre>
													Bad:{' '}
													{entrantsError.graphQLErrors.map(({ message }, i) => (
														<span key={i}>{message}</span>
													))}
												</pre>
											</Segment>
										</Container>
									)
								}
								if (loadingEntrants && (!entrantsData || !entrantsData.entrants || entrantsData.entrants === 0)) {
									return (
										<Container>
											<Loader active>Loading {isAuthenticatedUser(event.username) ? 'Entrants' : 'Event'}</Loader>
										</Container>
									)
								}
								const { entrants } = entrantsData
								return (
									<Container fluid>
										<Container style={this.props.fullScreen ? { display: 'none' } : {}}>
											<Breadcrumb>
												<Breadcrumb.Section as={Link} to="/home">
													Home
												</Breadcrumb.Section>
												<Breadcrumb.Divider icon="right angle" />
												<Breadcrumb.Section as={Link} to={isAuthenticatedUser(event.username) ? '/userEvents' : '/events'}>
													{isAuthenticatedUser(event.username) ? 'Manage Events' : 'Events'}
												</Breadcrumb.Section>
												<Breadcrumb.Divider icon="right angle" />
												<Breadcrumb.Section active>{event ? event.eventName : 'Loading...'}</Breadcrumb.Section>
											</Breadcrumb>
											<Popup
												trigger={
													<Button basic labelPosition="left" onClick={this.props.setFullScreen} floated="right">
														<Icon className="expand" size="large" />
													</Button>
												}
												content="Expand"
												position="bottom right"
											/>
											{isAuthenticatedUser(event.username) && (
												<Popup
													trigger={
														<Button basic labelPosition="left" as={Link} to={'/editEvent/' + event.id} floated="right">
															<Icon className="trash alternate" size="large" />
														</Button>
													}
													content="Delete Event"
													position="bottom right"
												/>
											)}
											{isAuthenticatedUser(event.username) && (
												<Popup
													trigger={
														<Button basic labelPosition="left" as={Link} to={'/editEvent/' + event.id} floated="right">
															<Icon className="edit" size="large" />
														</Button>
													}
													content="Edit Event"
													position="bottom right"
												/>
											)}
											{event.eventStatus === 'active' &&
												isAuthenticatedUser(event.username) &&
												moment().isAfter(moment(event.startDateTime * 1000).subtract(event.weighIn, 'hours')) &&
												day + 1 <= event.days && (
													<Popup
														trigger={
															<Button
																basic
																labelPosition="left"
																onClick={e => {
																	this.openEntrantModal()
																}}
																floated="right">
																<Icon name="user plus" size="large" />
															</Button>
														}
														content="Add Entrant"
														position="bottom right"
													/>
												)}
											{event.eventStatus === 'active' &&
												isAuthenticatedUser(event.username) &&
												!event.liveStatus &&
												entrants.length > 0 &&
												moment().isAfter(moment(event.startDateTime * 1000).subtract(10, 'minutes')) && (
													<Mutation mutation={SetLiveStatusMutation} key={event.id + '-setLiveStatus'}>
														{(setLiveStatus, { loading: creating, error }) => (
															<Popup
																trigger={
																	<Button
																		basic
																		labelPosition="left"
																		loading={creating}
																		onClick={async e => {
																			e.preventDefault()
																			//Need to make sure 'squat' is the first lift, ie, pushpull days
																			await setLiveStatus({
																				variables: {
																					id: event.id,
																					liveStatus: {
																						day: 1,
																						lift: 'squat',
																						flight: entrants[0].flight,
																						attempt: 1,
																						lifter: entrants[0].id,
																						status: 'ready',
																					},
																				},
																			})
																		}}
																		floated="right">
																		<Icon name="power" size="large" disabled={creating} />
																	</Button>
																}
																content="Start Event"
																position="bottom right"
															/>
														)}
													</Mutation>
												)}
											<Divider />
										</Container>
										<Container fluid>
											<EventSubscriber
												subscribeToEntrantChanges={() => {
													subscribeToEntrants({
														document: EntrantChangeSubscription,
														variables: { event: event.id },
														onError: error => {
															if (error.errorMessage.includes('Socket')) {
																console.log('caught socket')
															}
														},
														updateQuery: (
															prev,
															{
																subscriptionData: {
																	data: { entrantChange },
																},
															}
														) => {
															return {
																entrants: [...prev.entrants.filter(entrant => entrant.id !== entrantChange.id), entrantChange],
															}
														},
													})
												}}
												subscribeToEventChanges={() => {
													subscribeToEvent({
														document: EventChangeSubscription,
														variables: { id: event.id },
														onError: error => {
															if (error.errorMessage.includes('Socket')) {
																console.log('caught socket')
															}
														},
														updateQuery: (
															prev,
															{
																subscriptionData: {
																	data: { eventChange },
																},
															}
														) => {
															return {
																event: eventChange,
															}
														},
													})
												}}
											/>
											<EntrantModal
												day={day + 1}
												event={event}
												entrant={editingEntrant}
												open={entrantModalOpen}
												closeEntrantModal={this.closeEntrantModal}
											/>
											{!event.liveStatus && !isAuthenticatedUser(event.username) && <span>Put event info here</span>}
											{!event.liveStatus && isAuthenticatedUser(event.username) && (
												<EventRegistration entrants={entrants} event={event} openEntrantModal={this.openEntrantModal} />
											)}
											{event.liveStatus && (
												<EventOperation
													day={day}
													entrants={entrants}
													event={event}
													liveStatus={event.liveStatus}
													openEntrantModal={this.openEntrantModal}
												/>
											)}
										</Container>
									</Container>
								)
							}}
						</Query>
					)
				}}
			</Query>
		)
	}
}

export default withMenu(Event)
