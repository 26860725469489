import React, { Component } from 'react'
import '../css/events.css'
import moment from 'moment'
import { Query } from 'react-apollo'
import { Loader, Container, Segment } from 'semantic-ui-react'
import EventOperation from './components/EventOperation'
import { withMenu } from '../containers/Menu'
import GetEvent from '../queries/GetEvent'
import GetEntrants from '../queries/GetEntrants'

class Result extends Component {
	render() {
		return (
			<Query query={GetEvent} variables={{ id: this.props.match.params.event }} fetchPolicy="cache-and-network">
				{({ data, error, loading }) => {
					if (error) {
						console.log(error)
						return (
							<Container>
								<Segment inverted color="red" tertiary>
									<pre>
										Bad:{' '}
										{error.graphQLErrors.map(({ message }, i) => (
											<span key={i}>{message}</span>
										))}
									</pre>
								</Segment>
							</Container>
						)
					}
					if (loading && (!data || !data.event)) {
						return (
							<Container>
								<Loader active>Loading Event</Loader>
							</Container>
						)
					}
					const { event } = data
					const day = Math.ceil(moment().diff(moment(event.startDateTime * 1000), 'days', true))
					return (
						<Query query={GetEntrants} variables={{ event: event.id }} fetchPolicy="cache-and-network">
							{({ data: entrantsData, error: entrantsError, loading: loadingEntrants }) => {
								if (entrantsError) {
									console.log(entrantsError)
									return (
										<Container>
											<Segment inverted color="red" tertiary>
												<pre>
													Bad:{' '}
													{entrantsError.graphQLErrors.map(({ message }, i) => (
														<span key={i}>{message}</span>
													))}
												</pre>
											</Segment>
										</Container>
									)
								}
								if (loadingEntrants && (!entrantsData || !entrantsData.entrants)) {
									return (
										<Container>
											<Loader active>Loading Results</Loader>
										</Container>
									)
								}
								const { entrants } = entrantsData
								return (
									<Container fluid>
										<EventOperation day={day} entrants={entrants} event={event} liveStatus={event.liveStatus} />
									</Container>
								)
							}}
						</Query>
					)
				}}
			</Query>
		)
	}
}

export default withMenu(Result)
