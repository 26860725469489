import gql from 'graphql-tag'

export default gql`
	query GetUserEvents($username: String!) {
		userEvents(username: $username) {
			__typename
			username
			id
			eventName
			eventStatus
			placeId
			location
			startDateTime
			days
			weighIn
			capacity
			equipmentOptions
			divisions {
				powerlifting
				pushpull
				benchonly
				deadliftonly
			}
			federation
			liveStatus {
				day
				lift
				flight
				attempt
				lifter
				status
			}
			image
		}
	}
`
