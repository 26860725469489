import React, { Component } from 'react'
import { Query } from 'react-apollo'
import '../css/events.css'
import { Link } from 'react-router-dom'
import { Button, Breadcrumb, Container, Icon, Loader, Menu, Popup, Segment } from 'semantic-ui-react'
import { withMenu } from '../containers/Menu'
import UserEventsChangeSubscription from '../queries/UserEventsChangeSubscription'
import GetUserEvents from '../queries/GetUserEvents'
import EventItems from './components/EventItems'
import moment from 'moment'

class UserEvents extends Component {
	state = {
		view: {
			sort: 'upcoming',
			upcoming: {
				mode: 'card',
			},
			past: {
				mode: 'list',
			},
		},
	}

	handleRefresh = () => {
		this.state.refetch()
	}

	setLoading = loading => {
		this.setState({
			loading: loading,
		})
	}

	setRefetch = refetch => {
		this.setState({
			refetch: refetch,
		})
	}

	setCardViewMode = () => {
		const { view } = this.state
		view[view.sort].mode = 'card'
		this.setState({ view })
	}

	setListViewMode = () => {
		const { view } = this.state
		view[view.sort].mode = 'list'
		this.setState({ view })
	}

	setPastSortView = () => {
		const { view } = this.state
		view.sort = 'past'
		this.setState({ view })
	}

	setUpcomingSortView = () => {
		const { view } = this.state
		view.sort = 'upcoming'
		this.setState({ view })
	}

	render() {
		const { view } = this.state
		const {
			userData: { username },
		} = this.props
		return (
			<Container>
				<Menu tabular>
					<Menu.Menu position="left">
						<Breadcrumb style={{ marginTop: 'auto', marginBottom: 'auto' }}>
							<Breadcrumb.Section as={Link} to="/home">
								Home
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon="right angle" />
							<Breadcrumb.Section active>Manage Events</Breadcrumb.Section>
						</Breadcrumb>
					</Menu.Menu>
					<Menu.Item name="Upcoming" active={view.sort === 'upcoming'} onClick={this.setUpcomingSortView} />
					<Menu.Item name="Past" active={view.sort === 'past'} onClick={this.setPastSortView} />
					<Menu.Menu position="right">
						{view[view.sort].mode === 'card' && (
							<Popup
								trigger={
									<Button basic labelPosition="left" onClick={this.setListViewMode} floated="right">
										<Icon className="bars" size="large" />
									</Button>
								}
								content="List View"
								position="bottom center"
							/>
						)}
						{view[view.sort].mode === 'list' && (
							<Popup
								trigger={
									<Button basic labelPosition="left" onClick={this.setCardViewMode} floated="right">
										<Icon className="grid layout" size="large" />
									</Button>
								}
								content="Card View"
								position="bottom center"
							/>
						)}
					</Menu.Menu>
				</Menu>
				<Container>
					<Query query={GetUserEvents} variables={{ username: username }} fetchPolicy="cache-and-network">
						{({ data, error, loading, refetch, subscribeToMore }) => {
							if (error) {
								return (
									<Segment inverted color="red" tertiary>
										<pre>
											Bad:{' '}
											{error.graphQLErrors.map(({ message }, i) => (
												<span key={i}>{message}</span>
											))}
										</pre>
									</Segment>
								)
							}
							if (!loading && (!data || !data.userEvents || data.userEvents.length === 0)) {
								return (
									<span>
										<br />
										No events found.
										<br />
									</span>
								)
							}
							if (loading && (!data || !data.userEvents || data.userEvents.length === 0)) {
								return <Loader active>Loading Events</Loader>
							}
							const events = data.userEvents.filter(event => {
								console.log(event.id, event.liveStatus)
								return (
									(view.sort === 'upcoming' &&
										event.eventStatus !== 'done' &&
										(event.liveStatus || moment().isBefore(moment(event.startDateTime * 1000).add(1, 'hours')))) ||
									(view.sort === 'past' &&
										(event.eventStatus === 'done' ||
											(!event.liveStatus && moment().isAfter(moment(event.startDateTime * 1000).add(1, 'hours')))))
								)
							})
							events.sort((e1, e2) => {
								if (view.sort === 'upcoming') return e1.startDateTime - e2.startDateTime
								if (view.sort === 'past') return e2.startDateTime - e1.startDateTime
								return 0
							})
							return (
								<EventItems
									{...this.props}
									events={events}
									loading={loading}
									refetch={refetch}
									setLoading={this.setLoading}
									setRefetch={this.setRefetch}
									subscribeToEventChanges={() => {
										subscribeToMore({
											document: UserEventsChangeSubscription,
											variables: { username: username },
											updateQuery: (
												prev,
												{
													subscriptionData: {
														data: { eventChange },
													},
												}
											) => ({
												...prev,
												event: [...prev.event.filter(event => event.id !== eventChange.id), eventChange],
											}),
										})
									}}
									viewEvent={this.viewEvent}
									viewMode={view[view.sort].mode}
								/>
							)
						}}
					</Query>
				</Container>
			</Container>
		)
	}
}

export default withMenu(UserEvents)
