import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { Button, Form, Label, Input, Segment, List, Icon, Grid } from 'semantic-ui-react'
import DatePicker from 'react-datepicker'
import SemanticDatePicker from '../../components/SemanticDatePicker'
import moment from 'moment'
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps'
import PlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-places-autocomplete'
import '../../css/createevent.css'

const MapWithAMarker = withGoogleMap(props => (
	<GoogleMap defaultZoom={15} center={props.latLong}>
		<Marker position={props.latLong} />
	</GoogleMap>
))

const searchOptions = {
	types: ['establishment'],
}

export default class CreateEventInfo extends React.Component {
	constructor(props) {
		super(props)
		this.state = this.getInitialState(props.event, props.image, props.location)
	}

	fileInputRef = React.createRef()

	addTestData = () => {
		this.setState(
			this.getInitialState(
				{
					eventName: 'Novice Comp',
					placeId: 'ChIJn4UQEKkY1GoRgDDK_ymZEmg',
					location: 'Be Strong Fitness Geelong, McClelland Avenue, Ave Lara VIC, Australia',
					startDateTime: moment()
						.add(1, 'day')
						.startOf('hour')
						.unix(),
				},
				undefined,
				{
					address: 'Be Strong Fitness Geelong, McClelland Avenue, Ave Lara VIC, Australia',
					latLong: { lat: -38.027181, lng: 144.430749 },
				}
			)
		)
	}

	clearTestData = () => {
		this.setState(this.getInitialState())
	}

	getInitialState = (event, image, location) => ({
		event: {
			eventName: event && event.eventName ? event.eventName : '',
			startDateTime: event && event.startDateTime ? event.startDateTime : null,
			placeId: event && event.placeId ? event.placeId : '',
			location: event && event.location ? event.location : '',
		},
		location: {
			address: location && location.address ? location.address : '',
			latLong: location && location.latLong ? location.latLong : undefined,
		},
		image: image,
		touched: {
			eventName: false,
			startDateTime: false,
			placeId: false,
		},
		validationErrors: {
			eventName: '',
			startDateTime: '',
			placeId: '',
		},
	})

	handleBlur = name => async e => {
		this.setState({
			touched: { ...this.state.touched, [name]: true },
			validationErrors: { ...this.state.validationErrors, [name]: this.validateField(name, this.state.event[name]) },
		})
	}

	handleFileChange = e => {
		const that = this
		const file = e.target.files[0]
		if (!file) {
			return
		}

		const { name, size, type } = file
		const reader = new FileReader()
		reader.onload = function(ev) {
			const url = ev.target.result
			that.setState({
				image: {
					preview: url,
					name: name,
					size: size,
					type: type,
					file: file,
				},
			})
		}
		reader.readAsDataURL(file)
	}

	handleChange = (e, { name, value }) => {
		const { event } = this.state

		event[name] = value

		this.setState({
			event,
			touched: { ...this.state.touched, [name]: true },
			validationErrors: { ...this.state.validationErrors, [name]: this.validateField(name, value) },
		})
	}

	handleAddressChange = address => {
		const { location } = this.state
		location.address = address
		this.setState({ location })
	}

	handlePlaceSelect = async (address, placeId) => {
		const { event, location } = this.state
		event.placeId = placeId
		event.location = address
		location.address = address
		try {
			const results = await geocodeByPlaceId(placeId)
			location.latLong = await getLatLng(results[0])
		} catch (err) {
			console.log(err)
		}
		this.setState({
			touched: { ...this.state.touched, placeId: true },
			validationErrors: { ...this.state.validationErrors, placeId: this.validateField('placeId', placeId) },
			event,
			location,
		})
	}

	handleStartChange = datetime => {
		const { event } = this.state

		event.startDateTime = datetime.unix()
		this.setState({ event })
	}

	shouldMarkError = field => {
		return this.state.touched[field] && this.state.validationErrors[field] !== ''
	}

	validateField(name, value) {
		const validations = {
			eventName: input => {
				return input.length === 0 ? 'Please enter a name for this event' : ''
			},
			startDateTime: input => {
				return input === null ? 'Please select a start date/time for lifting at your event' : ''
			},
			placeId: input => {
				return input.length === 0 ? 'Please select a location' : ''
			},
		}
		return validations[name](value)
	}

	render() {
		const { nextStep } = this.props
		const { event, image, location, validationErrors } = this.state
		const isEnabled = event.eventName && event.startDateTime && event.placeId && !Object.keys(validationErrors).some(x => validationErrors[x])
		return (
			<Form size="large" className="attached fluid" autoComplete="off">
				<Grid>
					<Grid.Row>
						<Grid.Column>
							<Form.Field error={this.shouldMarkError('eventName')}>
								<label>Event Name</label>
								<Input
									icon="pencil"
									iconPosition="left"
									type="text"
									placeholder="Event name"
									name="eventName"
									focus={true}
									value={event.eventName}
									autoComplete="nope"
									onChange={this.handleChange}
									onBlur={this.handleBlur('eventName')}
								/>
								{this.shouldMarkError('eventName') && (
									<Label basic color="red" pointing="above">
										{validationErrors.eventName}
									</Label>
								)}
							</Form.Field>
							<Form.Field>
								<label>Cover Image</label>
								<Segment
									basic
									textAlign="center"
									style={{
										marginLeft: 'auto',
										marginRight: 'auto',
										marginBottom: 0,
										marginTop: 0,
									}}>
									<Segment
										style={{
											marginLeft: 'auto',
											marginRight: 'auto',
											marginBottom: 0,
											marginTop: 0,
											height: `350px`,
											width: '350px',
											overflow: 'hidden',
										}}
										placeholder={typeof image === 'undefined'}
										basic={typeof image !== 'undefined'}>
										{image && (
											<img
												alt="preview"
												src={image.preview}
												style={{
													marginLeft: 'auto',
													marginRight: 'auto',
													width: '350px',
													height: '350px',
													objectFit: 'cover',
													marginBottom: 0,
													marginTop: 0,
												}}
											/>
										)}
										{!image && (
											<Grid textAlign="center" style={{ height: '100%' }}>
												<Grid.Row verticalAlign="middle">
													<Grid.Column>
														<Icon name="image outline" color="orange" size="large" />
													</Grid.Column>
												</Grid.Row>
											</Grid>
										)}
									</Segment>
									<br />
									<Button color="orange" size="large" onClick={() => this.fileInputRef.current.click()}>
										Choose cover image
									</Button>
									<input
										ref={this.fileInputRef}
										type="file"
										accept="image/png, image/x-png,image/jpeg"
										hidden
										onChange={this.handleFileChange}
									/>
									<br />
									(Ideal size is at least 500 x 500)
								</Segment>
							</Form.Field>
							<Form.Field error={this.shouldMarkError('startDateTime')}>
								<label>Event Start Date/Time</label>
								<DatePicker
									customInput={<SemanticDatePicker icon="calendar outline" placeholderText="Lifting start date/time" />}
									name="startDateTime"
									selected={event.startDateTime ? moment(event.startDateTime * 1000) : event.startDateTime}
									onChange={this.handleStartChange.bind(this)}
									showTimeSelect
									timeFormat="HH:mm"
									timeIntervals={30}
									dateFormat="LLL"
									timeCaption="Start"
									minDate={moment()}
									onBlur={this.handleBlur('startDateTime')}
								/>
								{this.shouldMarkError('startDateTime') && (
									<Label basic color="red" pointing="above">
										{validationErrors.startDateTime}
									</Label>
								)}
							</Form.Field>
							<Form.Field error={this.shouldMarkError('placeId')}>
								<label>Location</label>
								<PlacesAutocomplete
									value={location.address}
									onChange={this.handleAddressChange}
									onSelect={this.handlePlaceSelect}
									onBlur={this.handleBlur('placeId')}
									name="address"
									searchOptions={searchOptions}>
									{({ getInputProps, suggestions, getSuggestionItemProps }) => (
										<div>
											<Input
												{...getInputProps({
													icon: 'marker',
													iconPosition: 'left',
													type: 'text',
													placeholder: 'Search for a place',
													focus: true,
													onBlur: this.handleBlur('placeId'),
												})}
											/>
											{suggestions.length > 0 && (
												<Segment attached="bottom">
													<List selection>
														{suggestions.map(suggestion => {
															return (
																<List.Item {...getSuggestionItemProps(suggestion)}>
																	<List.Content>
																		<List.Header>{suggestion.formattedSuggestion.mainText}</List.Header>
																		{suggestion.formattedSuggestion.secondaryText}
																	</List.Content>
																</List.Item>
															)
														})}
													</List>
												</Segment>
											)}
										</div>
									)}
								</PlacesAutocomplete>
								{this.shouldMarkError('placeId') && (
									<Label basic color="red" pointing="above">
										{validationErrors.placeId}
									</Label>
								)}
							</Form.Field>
							{location.latLong && (
								<Form.Field>
									<MapWithAMarker
										latLong={location.latLong}
										containerElement={<div style={{ height: `300px` }} />}
										mapElement={<div style={{ height: `100%` }} />}
									/>
								</Form.Field>
							)}
							{!location.latLong && (
								<Form.Field>
									<Segment style={{ height: `300px` }} placeholder={true}>
										<Grid textAlign="center" style={{ height: '100%' }}>
											<Grid.Row verticalAlign="middle">
												<Grid.Column>
													<Icon name="map outline" color="orange" size="large" />
												</Grid.Column>
											</Grid.Row>
										</Grid>
									</Segment>
								</Form.Field>
							)}
							{process.env.NODE_ENV === 'development' && (
								<Button
									color="grey"
									size="large"
									floated="left"
									onClick={async e => {
										e.preventDefault()
										this.clearTestData()
									}}>
									Reset
								</Button>
							)}
							<Button
								animated
								color="orange"
								disabled={!isEnabled}
								floated="right"
								size="large"
								onClick={async e => {
									e.preventDefault()
									nextStep({ event, image, location })
								}}>
								<Button.Content visible>Next</Button.Content>
								<Button.Content hidden>
									<Icon name="arrow right" />
								</Button.Content>
							</Button>
							{process.env.NODE_ENV === 'development' && (
								<Button
									color="orange"
									size="large"
									floated="right"
									onClick={async e => {
										e.preventDefault()
										this.addTestData()
									}}>
									Test
								</Button>
							)}
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Form>
		)
	}
}
