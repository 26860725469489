import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import './index.css'
import 'semantic-ui-css/semantic.min.css'
import App from './App'
import registerServiceWorker from './registerServiceWorker'
import Amplify from 'aws-amplify'
import appSyncConfig from './AppSync'

Amplify.configure({
	Auth: {
		identityPoolId: 'us-west-2:e726b934-871a-4b59-840d-18821a37880a',
		region: 'us-west-2',
		userPoolId: 'us-west-2_YMSxMdQh9',
		userPoolWebClientId: '5g1k28vcg6qmla1kd5pi196gc',
		mandatorySignIn: false,
		cookieStorage: {
			domain: '.powerlifter.pro',
			path: '/',
			expires: 365,
			secure: true,
		},
	},
	Analytics: {
		disabled: true,
	},
	Storage: {
		AWSS3: {
			bucket: 'images.powerlifter.pro', //REQUIRED -  Amazon S3 bucket
			region: 'us-west-2', //OPTIONAL -  Amazon service region
		},
	},
	aws_appsync_graphqlEndpoint: appSyncConfig.graphqlEndpoint,
	aws_appsync_region: appSyncConfig.region,
	aws_appsync_authenticationType: 'AWS_IAM',
})

ReactDOM.render(
	<Router>
		<App />
	</Router>,
	document.getElementById('root')
)
registerServiceWorker()
