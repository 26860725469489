import React, { Component } from 'react'
import { Auth } from 'aws-amplify'
import { Container, Loader } from 'semantic-ui-react'
import GetUserProfile from '../queries/GetUserProfile'

const full = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	height: '100vh',
}

export function withAuthenticator(Cmponent) {
	return class extends Component {
		constructor(props) {
			super(props)

			this.updateAuthState = this.updateAuthState.bind(this)
		}

		state = {
			authenticated: this.props.authenticated || false,
			authData: this.props.authData || null,
			authenticating: this.props.authenticating || true,
		}

		componentDidMount = async () => {
			this._isMounted = true
			this.checkUser()
		}

		componentWillUnmount = () => {
			this._isMounted = false
		}

		updateAuthState = async (authenticated, data) => {
			this.setState({ authenticated: authenticated, authData: data })
		}

		usernameFromAuthData = () => {
			const { authData } = this.props
			if (!authData) {
				return ''
			}

			let username = ''
			if (typeof authData === 'object') {
				// user object
				username = authData.user ? authData.user.username : authData.username
			} else {
				username = authData // username string
			}

			return username
		}

		checkUser = async () => {
			const { authenticated } = this.state
			try {
				const user = await Auth.currentAuthenticatedUser()
				const userProfile = await this.getUserProfile(user.username)
				this.setState({
					authenticated: true,
					authData: user,
					authenticating: false,
					userProfile: userProfile,
				})
			} catch (err) {
				console.log(err)
				if (!this._isMounted) {
					return
				}
				if (authenticated) {
					this.signOut()
					this.setState({
						authenticated: false,
					})
				}
				this.setState({
					authenticating: false,
				})
			}
		}

		getUserProfile = async username => {
			const { data } = await this.props.client.query({
				query: GetUserProfile,
				fetchPolicy: 'cache-network',
				variables: { username: username },
			})
			return data.getUserProfile
		}

		isAuthenticatedUser = username => {
			const { authenticated, authData } = this.state
			return authenticated && authData.username === username
		}

		signIn = async (username, password) => {
			try {
				const user = await Auth.signIn(username, password)
				const userProfile = await this.getUserProfile(user.username)
				this.setState({
					authenticated: true,
					authData: user,
					authenticating: false,
					userProfile: userProfile,
				})
				return true
			} catch (err) {
				console.log(err)
				await this.signOut()
				return false
			}
		}

		signOut = async () => {
			try {
				const { history } = this.props
				await Auth.signOut()
				await this.props.client.resetStore()
				this.setState({
					authenticated: false,
					authData: null,
					authenticating: false,
				})
				history.push('/')
			} catch (err) {
				console.log(err)
			}
		}

		render() {
			const { authenticated, authData, authenticating, userProfile } = this.state
			if (authenticating)
				return (
					<Container style={full}>
						<Loader active inline="centered" size="huge">
							Prepping the Platform
						</Loader>
					</Container>
				)
			else {
				return (
					<Cmponent
						{...this.props}
						authenticated={authenticated}
						authData={authData}
						isAuthenticatedUser={this.isAuthenticatedUser}
						signIn={this.signIn}
						signOut={this.signOut}
						updateAuthState={this.updateAuthState}
						userProfile={userProfile}
					/>
				)
			}
		}
	}
}
