import gql from 'graphql-tag'

export default gql(`
mutation($id: ID!, $liveStatus: LiveStatusInput!) {
  setLiveStatus(
	id: $id
	liveStatus: $liveStatus
  ) {
	id
	username
	eventName
	eventStatus
	placeId
	location
	startDateTime
	days
	weighIn
	capacity
	notes
	equipmentOptions
	divisions {
		powerlifting
		pushpull
		benchonly
		deadliftonly
	}
	federation
	liveStatus {
		day
		lift
		flight
		attempt
		lifter
		status
	}
	image
  }
}`)
