import gql from 'graphql-tag'

export default gql(`
mutation(
	$username: String!
	$fullname: String!
	$gender: String!
	$dateOfBirth: Int!) {
  createUserProfile(
	username: $username
	fullname: $fullname
	gender: $gender
	dateOfBirth: $dateOfBirth
  ) {
	  __typename
	  username
	  fullname
	  gender
	  dateOfBirth
	  updatedAt
  }
}`)
