export const getEventType = (event, entrant) => {
	const validEvents = Object.keys(event.divisions)
	if (entrant.squat1 && entrant.bench1 && entrant.deadlift1 && validEvents.includes('powerlifting')) {
		return 'powerlifting'
	} else if (entrant.bench1 && entrant.deadlift1 && validEvents.includes('pushpull')) {
		return 'pushpull'
	} else if (entrant.bench1 && validEvents.includes('benchonly')) {
		return 'benchonly'
	} else if (entrant.deadlift1 && validEvents.includes('deadliftonly')) {
		return 'deadliftonly'
	} else {
		return 'error'
	}
}
