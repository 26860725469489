import React, { Component } from 'react'
import { Auth } from 'aws-amplify'
import shorthash from 'shorthash'
import { default as uuid } from 'uuid'
import { Button, Form, Grid, Header, Image, Message, Label, Input, Icon } from 'semantic-ui-react'
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber'
import '../css/signup.css'

export default class Signup extends Component {
	state = {
		loading: false,
		resend: false,
		emailorphone: '',
		password: '',
		confirmationCode: '',
		newUser: null,
		checkUserExists: false,
		passwordHidden: true,
		error: null,
		focus: {
			password: false,
			emailorphone: false,
			confirmationCode: false,
		},
		touched: {
			password: false,
			emailorphone: false,
			confirmationCode: false,
		},
		validationErrors: {
			password: '',
			emailorphone: '',
			confirmationCode: '',
		},
		errors: {
			password: '',
			emailorphone: '',
			confirmationCode: '',
		},
		validating: {
			password: false,
			emailorphone: false,
			confirmationCode: false,
		},
	}

	signupUser = async e => {
		e.preventDefault()
		this.setState({ loading: true })
		let attributes = {}
		const emailorphone = this.state.emailorphone.toLowerCase()
		if (this.validEmail(emailorphone)) {
			attributes.email = emailorphone
		} else {
			const phoneUtil = PhoneNumberUtil.getInstance()
			try {
				const phoneNumber = phoneUtil.parse(emailorphone, this.props.userCountry)
				attributes.phone_number = phoneUtil.format(phoneNumber, PhoneNumberFormat.E164)
			} catch (err) {
				this.setState({
					errors: { ...this.state.errors, emailorphone: 'Unable to format phone number' },
					loading: false,
				})
				return
			}
		}
		const hash = shorthash.unique(emailorphone)
		Auth.signUp({
			username: hash,
			password: this.state.password,
			attributes: attributes,
		})
			.then(data => {
				this.setState({
					newUser: data,
					touched: {
						confirmationCode: false,
					},
					validationErrors: {
						confirmationCode: '',
					},
					errors: {
						confirmationCode: '',
					},
					validating: {
						confirmationCode: false,
					},
				})
			})
			.catch(err => {
				if (err && err.code === 'NetworkError') {
					this.setState({
						error: {
							errorHeader: 'Network Error',
							errorMessage: 'Unable to connect to the server, please check your connectivity and try again.',
						},
					})
				}
			})

		this.setState({ loading: false })
	}

	resendSignup = async event => {
		event.preventDefault()
		this.setState({ loading: true, error: null, confirmationCode: '' })
		const hash = shorthash.unique(this.state.emailorphone.toLowerCase())
		Auth.resendSignUp(hash)
			.then(data => {
				this.setState({
					resend: false,
					newUser: data,
					touched: {
						emailorphone: false,
					},
					validationErrors: {
						emailorphone: '',
					},
					errors: {
						emailorphone: '',
					},
					validating: {
						emailorphone: false,
					},
				})
			})
			.catch(err => {
				this.setState({ error: err })
			})
		this.setState({ loading: false })
	}

	confirmSignup = async event => {
		event.preventDefault()
		const { history, signIn } = this.props
		this.setState({ loading: true, error: null })
		const username = await shorthash.unique(this.state.emailorphone.toLowerCase())
		try {
			await Auth.confirmSignUp(username, this.state.confirmationCode)
			if (this.state.password) {
				try {
					console.log('Signing in')
					await signIn(username, this.state.password)
					history.push('/home')
				} catch (err) {
					this.setState({ loading: false, error: err.message })
				}
			} else {
				history.push('/signin/' + this.state.emailorphone + '?redirect=/home')
			}
		} catch (err) {
			this.setState({
				loading: false,
				errors: {
					confirmationCode: err.message,
				},
			})
		}
	}

	checkUserExists = async () => {
		if (this.state.checkUserExists) {
			this.setState({
				validating: { ...this.state.validating, emailorphone: true },
				errors: { ...this.state.errors, emailorphone: '' },
			})
			const hash = shorthash.unique(this.state.emailorphone.toLowerCase())
			Auth.signIn(hash, uuid.v4())
				.then(data => {
					this.setState({
						errors: {
							...this.state.errors,
							emailorphone:
								this.state.checkUserExists || this.state.validating['emailorphone'] ? 'An account already exists with this email!' : '',
						},
						validating: { ...this.state.validating, emailorphone: false },
					})
				})
				.catch(err => {
					this.setState({
						errors: {
							...this.state.errors,
							emailorphone:
								(this.state.checkUserExists || this.state.validating['emailorphone']) &&
								(err.code === 'NotAuthorizedException' || err.code === 'UserNotConfirmedException')
									? 'An account already exists with this email!'
									: '',
						},
						validating: { ...this.state.validating, emailorphone: false },
					})
				})
		}
	}

	validPassword(password) {
		const minLength = /^[\s\S]{8,}$/,
			lower = /[a-z]/,
			number = /[0-9]/,
			special = /[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/
		return minLength.test(password) && lower.test(password) && number.test(password) && special.test(password)
	}

	validEmail(email) {
		const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		return emailRegex.test(email)
	}

	validPhone(phone) {
		const phoneUtil = PhoneNumberUtil.getInstance()
		try {
			const phoneNumber = phoneUtil.parse(phone, 'AU')
			return phoneUtil.isValidNumber(phoneNumber)
		} catch (err) {
			return false
		}
	}

	validateField(name, value) {
		const validations = {
			password: input => {
				return this.validPassword(String(input.toLowerCase())) ? '' : 'Your password is weak, like your bench press!'
			},
			emailorphone: input => {
				return input.length === 0
					? 'Value cannot by empty!'
					: this.validEmail(String(input.toLowerCase())) || this.validPhone(input)
					? ''
					: "This doesn't seem to be a valid email or phone number"
			},
			confirmationCode: input => {
				return input.length === 0 ? 'Code cannot by empty!' : ''
			},
		}
		return validations[name](value)
	}

	handleChange = (event, { name, value }) => {
		this.setState({
			[name]: value,
		})
		if (this.state.touched[name] && !this.state.focus[name]) {
			this.setState({
				focus: { ...this.state.focus, [name]: true },
			})
		}
		if (this.state.errors[name]) {
			this.setState({
				errors: { ...this.state.errors, [name]: '' },
			})
		}
		if (this.state.validationErrors[name]) {
			this.setState({
				validationErrors: { ...this.state.validationErrors, [name]: '' },
			})
		}
	}

	handleBlur = (name, checkUserExists) => async event => {
		const validationError = this.validateField(name, this.state[name])
		this.setState({
			touched: { ...this.state.touched, [name]: true },
			validationErrors: { ...this.state.validationErrors, [name]: validationError },
			focus: { ...this.state.focus, [name]: false },
		})
		if (name === 'emailorphone' && !validationError && checkUserExists) {
			this.checkUserExists()
		}
	}

	handleChangeWithTouch = (event, { name, value }) => {
		this.setState({
			[name]: value,
			focus: { ...this.state.focus, [name]: true },
			touched: { ...this.state.touched, [name]: true },
			validationErrors: { ...this.state.validationErrors, [name]: this.validateField(name, value) },
		})
	}

	handleFocus = name => async event => {
		if (!this.state.touched[name]) {
			this.setState({
				focus: { ...this.state.focus, [name]: true },
			})
		}
		if ((name === 'emailorphone' && !this.state.checkUserExists) || (name !== 'emailorphone' && this.state.checkUserExists)) {
			this.setState({
				checkUserExists: name === 'emailorphone',
			})
		}
	}

	togglePassword = event => {
		this.setState({
			passwordHidden: this.state.passwordHidden ? false : true,
		})
	}

	handleResend = e => {
		e.preventDefault()
		this.setState({
			resend: true,
			checkUserExists: false,
			touched: {
				password: false,
				emailorphone: false,
				confirmationCode: false,
			},
			validationErrors: {
				password: '',
				ememailorphoneail: '',
				confirmationCode: '',
			},
			errors: {
				password: '',
				emailorphone: '',
				confirmationCode: '',
			},
			validating: {
				password: false,
				emailorphone: false,
				confirmationCode: false,
			},
		})
	}

	shouldMarkError = field => {
		return this.state.touched[field] && (this.state.errors[field] !== '' || this.state.validationErrors[field] !== '')
	}

	renderForm() {
		const { password, email, loading, error, touched, errors, validationErrors, validating, focus, passwordHidden } = this.state
		const isEnabled =
			touched['emailorphone'] &&
			touched['password'] &&
			!(Object.keys(errors).some(x => errors[x]) || Object.keys(validationErrors).some(x => validationErrors[x]))
		return (
			<div>
				<div className="signup-form">
					<style>{`
						body > div,
						body > div > div,
						body > div > div > div.signup-form {
							height: 100%;
						}
						`}</style>
					<Grid textAlign="center" style={{ height: '100%' }} verticalAlign="middle">
						<Grid.Column style={{ maxWidth: 450 }} textAlign="left">
							<Header as="h2" color="teal" textAlign="center">
								<Image src="/logo.png" />
							</Header>
							<Form size="large" className="attached fluid segment" autoComplete="off" error={error}>
								<Message error header={error && error.errorHeader} content={error && error.errorMessage} />
								<Form.Field error={this.shouldMarkError('emailorphone')}>
									<Input
										type="text"
										icon={
											!this.shouldMarkError('emailorphone') && touched['emailorphone'] && !focus['emailorphone'] ? (
												<Icon name="checkmark" color="green" />
											) : this.shouldMarkError('emailorphone') && !focus['emailorphone'] ? (
												<Icon name="remove" color="red" />
											) : (
												'user'
											)
										}
										iconPosition="left"
										placeholder="Email or phone number"
										name="emailorphone"
										focus={true}
										value={email}
										loading={validating['emailorphone']}
										autoComplete="nope"
										onFocus={this.handleFocus('emailorphone')}
										onChange={this.handleChange}
										onBlur={this.handleBlur('emailorphone', true)}
									/>
									{this.shouldMarkError('emailorphone') && (
										<Label basic color="red" pointing="above">
											{errors.emailorphone ? errors.emailorphone : validationErrors.emailorphone}
										</Label>
									)}
									{!this.shouldMarkError('emailorphone') && focus['emailorphone'] && (
										<Label basic color="teal" pointing="above">
											We'll send a confirmation code to this email/phone.
										</Label>
									)}
									{validating['emailorphone'] && (
										<Label basic color="teal" pointing="above">
											Checking...
										</Label>
									)}
								</Form.Field>
								<Form.Field error={this.shouldMarkError('password') && !focus['password']}>
									<Input
										icon={
											!this.shouldMarkError('password') && touched['password'] ? (
												<Icon name="checkmark" color="green" />
											) : this.shouldMarkError('password') && !focus['password'] ? (
												<Icon name="remove" color="red" />
											) : (
												'lock'
											)
										}
										iconPosition="left"
										placeholder="Password"
										name="password"
										type={passwordHidden ? 'password' : 'text'}
										focus={true}
										value={password}
										action={<Button color="teal" icon={passwordHidden ? 'eye' : 'eye slash'} onClick={this.togglePassword} />}
										onFocus={this.handleFocus('password')}
										autoComplete="nope"
										onChange={this.handleChangeWithTouch}
										onBlur={this.handleBlur('password')}
									/>
									{this.shouldMarkError('password') && !focus['password'] && (
										<Label basic color="red" pointing="above" style={{ 'text-align': 'left' }}>
											{errors.password ? errors.password : validationErrors.password}
											<li>Make it 8 or more characters</li>
											<li>Use at least one number and one special character</li>
										</Label>
									)}
									{this.shouldMarkError('password') && focus['password'] && (
										<Label basic color="teal" pointing="above" style={{ 'text-align': 'left' }}>
											<li>Make it 8 or more characters</li>
											<li>Use at least one number and one special character</li>
										</Label>
									)}
								</Form.Field>
								<Button color="teal" disabled={!isEnabled} loading={loading} fluid size="large" onClick={this.signupUser}>
									Signup
								</Button>
							</Form>
							<Message>
								Click here to <a href="/home">login</a>
							</Message>
						</Grid.Column>
					</Grid>
				</div>
			</div>
		)
	}

	renderResendForm() {
		const { emailorphone, loading, errors, validationErrors } = this.state
		const isEnabled = !(Object.keys(errors).some(x => errors[x]) || Object.keys(validationErrors).some(x => validationErrors[x]))
		return (
			<div>
				<div className="signup-form">
					{/*
              Heads up! The styles below are necessary for the correct render of this example.
              You can do same with CSS, the main idea is that all the elements up to the `Grid`
              below must have a height of 100%.
            */}
					<style>{`
              body > div,
              body > div > div,
              body > div > div > div.signup-form {
                height: 100%;
              }
            `}</style>
					<Grid textAlign="center" style={{ height: '100%' }} verticalAlign="middle">
						<Grid.Column style={{ maxWidth: 450 }} textAlign="left">
							<Header as="h2" color="teal" textAlign="center">
								<Image src="/logo.png" />
							</Header>
							<Form size="large" loading={loading} className="attached fluid segment" autoComplete="off">
								<Form.Field error={this.shouldMarkError('emailorphone')}>
									{this.shouldMarkError('emailorphone') && (
										<Label basic color="red" pointing="below">
											{errors.emailorphone ? errors.emailorphone : validationErrors.emailorphone}
										</Label>
									)}
									{!this.shouldMarkError('emailorphone') && (
										<Label basic color="teal" pointing="below">
											The email or phone number you signed up with
										</Label>
									)}
									<Input
										type="text"
										iconPosition="left"
										icon="user"
										placeholder="Email or phone number"
										name="emailorphone"
										focus={true}
										value={emailorphone}
										autoComplete="nope"
										loading={loading}
										onChange={this.handleChangeW}
										onBlur={this.handleBlur('emailorphone', false)}
									/>
								</Form.Field>
								<Button fluid color="teal" disabled={!isEnabled} onClick={this.resendSignup}>
									Resend
								</Button>
							</Form>
						</Grid.Column>
					</Grid>
				</div>
			</div>
		)
	}

	renderConfirmationForm() {
		const { confirmationCode, loading, errors, validationErrors } = this.state
		const isEnabled = !Object.keys(validationErrors).some(x => validationErrors[x])
		return (
			<div>
				<div className="signup-form">
					{/*
              Heads up! The styles below are necessary for the correct render of this example.
              You can do same with CSS, the main idea is that all the elements up to the `Grid`
              below must have a height of 100%.
            */}
					<style>{`
              body > div,
              body > div > div,
              body > div > div > div.signup-form {
                height: 100%;
              }
            `}</style>
					<Grid textAlign="center" style={{ height: '100%' }} verticalAlign="middle">
						<Grid.Column style={{ maxWidth: 450 }} textAlign="left">
							<Header as="h2" color="teal" textAlign="center">
								<Image src="/logo.png" />
							</Header>
							<Form size="large" loading={loading} className="attached fluid segment">
								<Form.Field error={this.shouldMarkError('confirmationCode')}>
									{this.shouldMarkError('confirmationCode') && (
										<Label basic color="red" pointing="below">
											{errors.confirmationCode ? errors.confirmationCode : validationErrors.confirmationCode}
										</Label>
									)}
									{!this.shouldMarkError('confirmationCode') && (
										<Label basic color="teal" pointing="below">
											The confirmation code we sent you
										</Label>
									)}
									<Input
										type="text"
										icon="user"
										iconPosition="left"
										placeholder="Confirmation Code"
										name="confirmationCode"
										focus={true}
										value={confirmationCode}
										onBlur={this.handleBlur('confirmationCode')}
										onChange={this.handleChange}
									/>
								</Form.Field>
								<Button.Group fluid size="large" color="teal">
									<Button disabled={!isEnabled} onClick={this.confirmSignup}>
										Confirm
									</Button>
									<Button.Or />
									<Button onClick={this.resendSignup}>Resend</Button>
								</Button.Group>
							</Form>
						</Grid.Column>
					</Grid>
				</div>
			</div>
		)
	}

	render() {
		return (
			<div className="Signup">
				{this.state.newUser === null ? (this.state.resend ? this.renderResendForm() : this.renderForm()) : this.renderConfirmationForm()}
			</div>
		)
	}
}
