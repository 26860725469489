import React, { Component } from "react";
import { Auth } from 'aws-amplify';
import { Button, Form, Grid, Header, Image, Message, Label, Input, Icon } from "semantic-ui-react";
import "../css/forgot.css";

export default class Forgot extends Component {

    constructor(props) {
        super(props);

        this.state = {
            username: props.match.params.username ? props.match.params.username : '',
            password: '',
            confirmationCode: '',
            loading: false,
            confirmForgot: false,
            confirmResend: false,
            touched: {
                username: false,
                password: false,
                confirmationCode: false,
            },
            validationErrors: {
                username: '',
                password: '',
                confirmationCode: ''
            },
            error: '',
        }
    }

    forgotPassword = async (e) => {
        e.preventDefault();
        this.setState({ loading: true })
        Auth.forgotPassword(this.state.username)
            .then(data => {
                this.setState({ confirmForgot: true })
            })
            .catch(err => {
                if (err.code === 'UserNotConfirmedException') {
                    Auth.resendSignUp(this.state.username)
                        .then(data => {
                            this.setState({ confirmResend: true })
                        })
                        .catch(error => {
                            this.setState({
                                error: error.message
                            })
                        });
                } else {
                    this.setState({
                        error: err.message
                    })
                }
            });
        this.setState({ loading: false })
    }

    confirmPassword = async event => {
        event.preventDefault();
        this.setState({ loading: true, error: null });
        Auth.forgotPasswordSubmit(this.state.username, this.state.confirmationCode, this.state.password)
            .then(data => {
                this.props.history.push('/login/' + this.state.username);
            })
            .catch(err => {
                this.setState({
                    loading: false, errors: {
                        confirmationCode: err.message
                    }
                })
            });
    }

    confirmSignup = async event => {
        event.preventDefault();
        this.setState({ loading: true, error: null });
        Auth.confirmSignUp(this.state.username, this.state.confirmationCode)
            .then(data => {
                this.props.history.push('/login/' + this.state.username);
            })
            .catch(err => {
                this.setState({
                    loading: false, errors: {
                        confirmationCode: err.message
                    }
                })
            });
    }

    validateField = (name, value) => ({
        username: value.length === 0 ? 'Username cannot by empty!' : '',
        password: value.length > 0 ? '' : 'You need a password!',
        confirmationCode: value.length === 0 ? 'Code cannot by empty!' : '',
    })[name]

    handleChange = (event, { name, value }) => {
        this.setState({
            [name]: value,
        });
    }

    handleBlur = (name, checkUserExists) => async (event) => {
        this.setState({
            touched: { ...this.state.touched, [name]: true },
            validationErrors: { ...this.state.validationErrors, [name]: this.validateField(name, this.state[name]) }
        });
    }

    handleChangeWithTouch = (event, { name, value }) => {
        this.setState({
            [name]: value,
            touched: { ...this.state.touched, [name]: true },
            validationErrors: { ...this.state.validationErrors, [name]: this.validateField(name, value) }
        });
    }

    shouldMarkError = (field) => {
        return this.state.touched[field] && (this.state.error !== '' || this.state.validationErrors[field] !== '')
    }

    renderForm() {
        const { username, error, validationErrors, loading } = this.state
        const isEnabled = !Object.keys(validationErrors).some(x => validationErrors[x]);

        return (
            <div>
                <div className='login-form'>
                    {/*
              Heads up! The styles below are necessary for the correct render of this example.
              You can do same with CSS, the main idea is that all the elements up to the `Grid`
              below must have a height of 100%.
            */}
                    <style>{`
              body > div,
              body > div > div,
              body > div > div > div.login-form {
                height: 100%;
              }
            `}</style>
                    <Grid
                        textAlign='center'
                        style={{ height: '100%' }}
                        verticalAlign='middle'
                    >
                        <Grid.Column style={{ maxWidth: 450 }} textAlign='left'>
                            <Header as='h2' color='teal' textAlign='center'>
                                <Image src='/logo.png' />
                            </Header>
                            <Form size='large' className='attached fluid segment' autoComplete='off'>
                                <Form.Field error={this.shouldMarkError('username')}>
                                    {this.shouldMarkError('username') && <Label basic color='red' pointing='below'>
                                        {error ? error : validationErrors.username}
                                    </Label>}
                                    <Input type='text'
                                        icon='user'
                                        iconPosition='left'
                                        placeholder='Username'
                                        name='username'
                                        focus={true}
                                        value={username}
                                        onChange={this.handleChange}
                                        onBlur={this.handleBlur('username', true)} />
                                </Form.Field>
                                <Button color='teal' loading={loading} disabled={!isEnabled} fluid size='large' onClick={this.forgotPassword}>Submit</Button>
                            </Form>
                            <Message textalign='center'>
                                New to us? <a href='/signup'>Sign Up</a>
                            </Message>
                        </Grid.Column>
                    </Grid>
                </div>
            </div>
        );
    }

    renderPasswordForm() {
        const { password, confirmationCode, loading, error, validationErrors } = this.state
        const isEnabled = !(this.state.error || Object.keys(validationErrors).some(x => validationErrors[x]));
        return (

            <div>
                <div className='signup-form'>
                    {/*
              Heads up! The styles below are necessary for the correct render of this example.
              You can do same with CSS, the main idea is that all the elements up to the `Grid`
              below must have a height of 100%.
            */}
                    <style>{`
              body > div,
              body > div > div,
              body > div > div > div.signup-form {
                height: 100%;
              }
            `}</style>
                    <Grid
                        textAlign='center'
                        style={{ height: '100%' }}
                        verticalAlign='middle'
                    >
                        <Grid.Column style={{ maxWidth: 450 }} textAlign='left'>
                            <Header as='h2' color='teal' textAlign='center'>
                                <Image src='/logo.png' />
                            </Header>
                            <Form size='large' loading={loading} className='attached fluid segment'>
                                <Form.Field error={this.shouldMarkError('confirmationCode')}>
                                    {this.shouldMarkError('confirmationCode') && <Label basic color='red' pointing='below'>
                                        {error ? error : validationErrors.confirmationCode}
                                    </Label>}
                                    {!this.shouldMarkError('confirmationCode') && <Label basic color='teal' pointing='below'>
                                        We sent a code to your email confirm this is actually you.
                                    </Label>}
                                    <Input type='text'
                                        icon='user'
                                        iconPosition='left'
                                        placeholder='Confirmation Code'
                                        name='confirmationCode'
                                        focus={true}
                                        value={confirmationCode}
                                        onBlur={this.handleBlur('confirmationCode')}
                                        onChange={this.handleChange} />
                                </Form.Field>
                                <Form.Field error={this.shouldMarkError('password')}>
                                    {this.shouldMarkError('password') && validationErrors.password && <Label basic color='red' pointing='below'>
                                        {validationErrors.password}
                                    </Label>}
                                    {!this.shouldMarkError('password') && <Label basic color='teal' pointing='below'>
                                        Enter a new password.
                                    </Label>}
                                    <Input
                                        icon={<Icon name='eye' />}
                                        placeholder='Password'
                                        iconPosition='left'
                                        name='password'
                                        type='password'
                                        focus={true}
                                        value={password}
                                        onChange={this.handleChangeWithTouch} />
                                </Form.Field>
                                <Button.Group fluid size='large' color='teal'>
                                    <Button disabled={!isEnabled} onClick={this.confirmPassword}>Confirm</Button>
                                </Button.Group>
                            </Form>
                        </Grid.Column>
                    </Grid>
                </div>
            </div >
        )
    }

    renderConfirmationForm() {
        const { confirmationCode, loading, error, validationErrors } = this.state
        const isEnabled = !(this.state.error || Object.keys(validationErrors).some(x => validationErrors[x]));
        return (

            <div>
                <div className='signup-form'>
                    {/*
              Heads up! The styles below are necessary for the correct render of this example.
              You can do same with CSS, the main idea is that all the elements up to the `Grid`
              below must have a height of 100%.
            */}
                    <style>{`
              body > div,
              body > div > div,
              body > div > div > div.signup-form {
                height: 100%;
              }
            `}</style>
                    <Grid
                        textAlign='center'
                        style={{ height: '100%' }}
                        verticalAlign='middle'
                    >
                        <Grid.Column style={{ maxWidth: 450 }} textAlign='left'>
                            <Header as='h2' color='teal' textAlign='center'>
                                <Image src='/logo.png' />
                            </Header>
                            <Form size='large' loading={loading} className='attached fluid segment'>
                                <Form.Field error={this.shouldMarkError('confirmationCode')}>
                                    {this.shouldMarkError('confirmationCode') && <Label basic color='red' pointing='below'>
                                        {error ? error : validationErrors.confirmationCode}
                                    </Label>}
                                    {!this.shouldMarkError('confirmationCode') && <Label basic color='teal' pointing='below'>
                                        Your account isn't confirmed yet, we've sent a code to your email.
                                </Label>}
                                    <Input type='text'
                                        icon='user'
                                        iconPosition='left'
                                        placeholder='Confirmation Code'
                                        name='confirmationCode'
                                        focus={true}
                                        value={confirmationCode}
                                        onBlur={this.handleBlur('confirmationCode')}
                                        onChange={this.handleChange} />
                                </Form.Field>
                                <Button.Group fluid size='large' color='teal'>
                                    <Button disabled={!isEnabled} onClick={this.confirmSignup}>Confirm</Button>
                                </Button.Group>
                            </Form>
                        </Grid.Column>
                    </Grid>
                </div>
            </div >
        )
    }

    render() {
        return (
            <div className="Login">
                {this.state.confirmResend ? this.renderConfirmationForm() : this.state.confirmForgot ? this.renderPasswordForm() : this.renderForm()}
            </div>
        );
    }
}