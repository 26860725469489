import React, { Component } from 'react'
import { Mutation } from 'react-apollo'
import { Button, Form, Header, Input, Modal, Icon, Label, Dropdown } from 'semantic-ui-react'
import CreateEntrantMutation from '../queries/CreateEntrantMutation'
import UpdateEntrantMutation from '../queries/UpdateEntrantMutation'
import '../fontello/css/plp.css'

const genderOptions = [{ key: 'f', text: 'Female', value: 'F' }, { key: 'm', text: 'Male', value: 'M' }]

export default class EntrantModal extends Component {
	constructor(props) {
		super(props)
		this.state = this.getInitialState(this.props.event, this.props.entrant, this.props.open, this.props.day)
	}

	getInitialState = (event, entrant, open, day) => ({
		open: open,
		created: false,
		mode: entrant && entrant.id ? 'update' : 'create',
		entrant: {
			event: event.id,
			name: entrant && entrant.name ? entrant.name : '',
			gender: entrant && entrant.gender ? entrant.gender : '',
			age: entrant && entrant.age ? entrant.age : '',
			weight: entrant && entrant.weight ? entrant.weight : '',
			status: entrant && entrant.status ? entrant.status : 'pending',
			equipment: entrant && entrant.equipment ? entrant.equipment : 'R',
			rackHeight: entrant && entrant.rackHeight ? entrant.rackHeight : '',
			racksInOut: entrant && entrant.racksInOut ? entrant.racksInOut : 'out',
			benchHeight: entrant && entrant.benchHeight ? entrant.benchHeight : '',
			faceSaverHeight: entrant && entrant.faceSaverHeight ? entrant.faceSaverHeight : '',
			squat1:
				entrant && entrant.squat1
					? {
							weight: entrant.squat1.weight,
							status: entrant.squat1.status,
					  }
					: '',
			bench1:
				entrant && entrant.bench1
					? {
							weight: entrant.bench1.weight,
							status: entrant.bench1.status,
					  }
					: '',
			deadlift1:
				entrant && entrant.deadlift1
					? {
							weight: entrant.deadlift1.weight,
							status: entrant.deadlift1.status,
					  }
					: '',
			flight: entrant && entrant.flight ? entrant.flight : 'A',
			day: entrant && entrant.day ? entrant.day : day,
		},
		event: event,
		touched: {
			name: false,
			gender: false,
			age: false,
			weight: false,
			squat1: false,
			bench1: false,
			deadlift1: false,
		},
		validationErrors: {
			name: '',
			gender: '',
			age: '',
			weight: '',
			squat1: '',
			bench1: '',
			deadlift1: '',
		},
		focused: {
			name: false,
			gender: false,
			age: false,
			weight: false,
			squat1: false,
			bench1: false,
			deadlift1: false,
		},
	})

	componentDidUpdate = prevProps => {
		if (this.props.entrant !== prevProps.entrant || this.props.event !== prevProps.event || this.props.open !== prevProps.open) {
			this.setState(this.getInitialState(this.props.event, this.props.entrant, this.props.open, this.props.day))
		}
	}

	close = () => this.props.closeEntrantModal()

	handleChange = (e, { name, value }) => {
		const { entrant } = this.state

		if (name === 'age') {
			try {
				entrant[name] = parseInt(value, 10)
			} catch (err) {
				entrant[name] = value
			}
		} else if (['squat1', 'bench1', 'deadlift1'].includes(name)) {
			if (entrant[name]) {
				entrant[name].weight = value
			} else {
				entrant[name] = {
					weight: value,
					status: 'notattempted',
				}
			}
		} else {
			entrant[name] = value
		}
		this.setState({ entrant })

		this.setState({
			validationErrors: { ...this.state.validationErrors, [name]: this.validateField(name, value) },
		})
	}

	handleBlur = name => async () => {
		const validationError = this.validateField(name, this.state.entrant[name])
		this.setState({
			touched: { ...this.state.touched, [name]: true },
			validationErrors: { ...this.state.validationErrors, [name]: validationError },
			focused: { ...this.state.focused, [name]: false },
		})
	}

	handleFocus = name => async () => {
		if (!this.state.touched[name]) {
			this.setState({
				focused: { ...this.state.focused, [name]: true },
			})
		}
	}

	shouldMarkError = field => {
		return this.state.touched[field] && this.state.validationErrors[field] !== ''
	}

	reset = () => {
		this.setState(this.getInitialState(this.props.event, this.props.entrant, this.props.open, this.props.day))
	}

	validateField(name, value) {
		const validations = {
			name: input => {
				return input.length < 3 ? 'Please enter a name' : ''
			},
			gender: input => {
				return input.length === 0 ? 'Please select a gender' : ''
			},
			age: input => {
				return input.length === 0 || isNaN(input) ? 'Please enter a valid age' : ''
			},
			weight: input => {
				return input.length === 0 || isNaN(input) ? 'Please enter a valid weight' : ''
			},
			equipment: input => {
				return ''
			},
			rackHeight: input => {
				return ''
			},
			racksInOut: input => {
				return ''
			},
			benchHeight: input => {
				return ''
			},
			faceSaverHeight: input => {
				return ''
			},
			squat1: input => {
				return input.length === 0 || !isNaN(input) ? '' : input && !isNaN(input.weight) ? '' : 'Please enter a valid attempt'
			},
			bench1: input => {
				return input.length === 0 || !isNaN(input) ? '' : input && !isNaN(input.weight) ? '' : 'Please enter a valid attempt'
			},
			deadlift1: input => {
				return input.length === 0 || !isNaN(input) ? '' : input && !isNaN(input.weight) ? '' : 'Please enter a valid attempt'
			},
		}
		return validations[name](value)
	}

	render() {
		const { open, entrant, event, validationErrors, focused, mode, touched } = this.state
		const isEnabled = entrant.name && entrant.gender && entrant.age && !Object.keys(validationErrors).some(x => validationErrors[x])
		return (
			<Mutation mutation={CreateEntrantMutation}>
				{(createEntrant, { loading: creating, error }) => (
					<Mutation mutation={UpdateEntrantMutation}>
						{(updateEntrant, { loading: updating, error }) => (
							<Modal dimmer="blurring" open={open} onClose={this.close} closeOnEscape={false} closeOnDimmerClick={false} size="tiny">
								<Modal.Content>
									<Header as="h3">
										<div>{`${mode === 'update' ? 'Edit' : 'New'} Entrant`}</div>
									</Header>
									<Form>
										<Form.Group>
											<Form.Field error={this.shouldMarkError('name')} width={12}>
												<Input
													type="text"
													icon={
														!this.shouldMarkError('name') && touched['name'] && !focused['name'] ? (
															<Icon name="checkmark" color="green" />
														) : this.shouldMarkError('name') && !focused['name'] ? (
															<Icon name="remove" color="red" />
														) : (
															'id card outline'
														)
													}
													iconPosition="left"
													placeholder="Name"
													name="name"
													value={entrant.name}
													autoComplete="nope"
													onChange={this.handleChange}
													onBlur={this.handleBlur('name')}
													onFocus={this.handleFocus('name')}
												/>
												{this.shouldMarkError('name') && (
													<Label basic color="red" pointing="above">
														{validationErrors.name}
													</Label>
												)}
											</Form.Field>
											<Form.Field error={this.shouldMarkError('gender')} width={4}>
												<Dropdown
													placeholder="Gender"
													fluid
													search
													selection
													options={genderOptions}
													name="gender"
													value={entrant.gender}
													onChange={this.handleChange}
													onBlur={this.handleBlur('gender')}
													onFocus={this.handleFocus('gender')}
												/>
												{this.shouldMarkError('gender') && (
													<Label basic color="red" pointing="above">
														{validationErrors.gender}
													</Label>
												)}
											</Form.Field>
										</Form.Group>
										{event.eventStatus !== 'live' && (
											<Form.Group>
												<Form.Field error={this.shouldMarkError('age')} width={5}>
													<Input
														type="text"
														icon={
															!this.shouldMarkError('age') && touched['age'] && !focused['age'] ? (
																<Icon name="checkmark" color="green" />
															) : this.shouldMarkError('age') && !focused['age'] ? (
																<Icon name="remove" color="red" />
															) : (
																'calendar alternate outline'
															)
														}
														iconPosition="left"
														placeholder="Age"
														name="age"
														value={entrant.age}
														autoComplete="nope"
														onChange={this.handleChange}
														onBlur={this.handleBlur('age')}
														onFocus={this.handleFocus('age')}
													/>
													{this.shouldMarkError('age') && (
														<Label basic color="red" pointing="above">
															{validationErrors.age}
														</Label>
													)}
												</Form.Field>
												<Form.Field error={this.shouldMarkError('weight')} width={5}>
													<Input
														type="text"
														icon={
															!this.shouldMarkError('weight') && touched['weight'] && !focused['weight'] ? (
																<Icon name="checkmark" color="green" />
															) : this.shouldMarkError('weight') && !focused['weight'] ? (
																<Icon name="remove" color="red" />
															) : (
																'weight'
															)
														}
														iconPosition="left"
														placeholder="Weight"
														name="weight"
														value={entrant.weight}
														autoComplete="nope"
														onChange={this.handleChange}
														onBlur={this.handleBlur('weight')}
														onFocus={this.handleFocus('weight')}
													/>
													{this.shouldMarkError('weight') && (
														<Label basic color="red" pointing="above">
															{validationErrors.weight}
														</Label>
													)}
												</Form.Field>
												<Form.Radio
													width={3}
													label="Raw"
													name="equipment"
													value="R"
													checked={entrant.equipment === 'R'}
													onChange={this.handleChange}
												/>
												<Form.Radio
													width={3}
													label="Equipped"
													name="equipment"
													value="E"
													checked={entrant.equipment === 'E'}
													onChange={this.handleChange}
												/>
											</Form.Group>
										)}
										<Form.Group>
											<Form.Field width={8}>
												<Input
													type="text"
													icon="arrows alternate vertical"
													iconPosition="left"
													placeholder="Rack Height"
													name="rackHeight"
													value={entrant.rackHeight}
													autoComplete="nope"
													onChange={this.handleChange}
												/>
											</Form.Field>
											<Form.Field width={8}>
												{' '}
												<span>
													Racks{' '}
													<Dropdown
														name="racksInOut"
														closeOnChange
														inline
														options={[
															{
																key: 'in',
																text: 'in',
																value: 'in',
															},
															{
																key: 'out',
																text: 'out',
																value: 'out',
															},
														]}
														defaultValue={entrant.racksInOut}
														onChange={this.handleChange}
													/>
												</span>
											</Form.Field>
										</Form.Group>
										<Form.Group>
											<Form.Field width={8}>
												<Input
													type="text"
													icon="arrows alternate vertical"
													iconPosition="left"
													placeholder="Bench Height"
													name="benchHeight"
													value={entrant.benchHeight}
													autoComplete="nope"
													onChange={this.handleChange}
												/>
											</Form.Field>
											<Form.Field width={8}>
												<Input
													type="text"
													icon="arrows alternate vertical"
													iconPosition="left"
													placeholder="Face Saver Height"
													name="faceSaverHeight"
													value={entrant.faceSaverHeight}
													autoComplete="nope"
													onChange={this.handleChange}
												/>
											</Form.Field>
										</Form.Group>
										{event.eventStatus !== 'live' && (
											<Form.Group widths="equal">
												<Form.Field error={this.shouldMarkError('squat1')}>
													<Input
														disabled={entrant.squat1 && entrant.squat1.status ? entrant.squat1.status !== 'notattempted' : false}
														fluid
														type="text"
														icon={
															!this.shouldMarkError('squat1') && touched['squat1'] && !focused['squat1'] && entrant.squat1 ? (
																<Icon name="checkmark" color="green" />
															) : this.shouldMarkError('squat1') && !focused['squat1'] ? (
																<Icon name="remove" color="red" />
															) : (
																<Icon className="icon-weightplate" />
															)
														}
														iconPosition="left"
														placeholder="Squat1"
														name="squat1"
														value={entrant.squat1 ? entrant.squat1.weight : ''}
														autoComplete="nope"
														onChange={this.handleChange}
														onBlur={this.handleBlur('squat1')}
														onFocus={this.handleFocus('squat1')}
													/>
													{this.shouldMarkError('squat1') && (
														<Label basic color="red" pointing="above">
															{validationErrors.squat1}
														</Label>
													)}
												</Form.Field>
												<Form.Field error={this.shouldMarkError('bench1')}>
													<Input
														disabled={entrant.bench1 && entrant.bench1.status ? entrant.bench1.status !== 'notattempted' : false}
														fluid
														type="text"
														icon={
															!this.shouldMarkError('bench1') && touched['bench1'] && !focused['bench1'] && entrant.bench1 ? (
																<Icon name="checkmark" color="green" />
															) : this.shouldMarkError('bench1') && !focused['bench1'] ? (
																<Icon name="remove" color="red" />
															) : (
																<Icon className="icon-weightplate" />
															)
														}
														iconPosition="left"
														placeholder="Bench 1"
														name="bench1"
														value={entrant.bench1 ? entrant.bench1.weight : ''}
														autoComplete="nope"
														onChange={this.handleChange}
														onBlur={this.handleBlur('bench1')}
														onFocus={this.handleFocus('bench1')}
													/>
													{this.shouldMarkError('bench1') && (
														<Label basic color="red" pointing="above">
															{validationErrors.bench1}
														</Label>
													)}
												</Form.Field>
												<Form.Field error={this.shouldMarkError('deadlift1')}>
													<Input
														disabled={
															entrant.deadlift1 && entrant.deadlift1.status ? entrant.deadlift1.status !== 'notattempted' : false
														}
														fluid
														type="text"
														icon={
															!this.shouldMarkError('deadlift1') &&
															touched['deadlift1'] &&
															!focused['deadlift1'] &&
															entrant.deadlift1 ? (
																<Icon name="checkmark" color="green" />
															) : this.shouldMarkError('deadlift1') && !focused['deadlift1'] ? (
																<Icon name="remove" color="red" />
															) : (
																<Icon className="icon-weightplate" />
															)
														}
														iconPosition="left"
														placeholder="Deadlift 1"
														name="deadlift1"
														value={entrant.deadlift1 ? entrant.deadlift1.weight : ''}
														autoComplete="nope"
														onChange={this.handleChange}
														onBlur={this.handleBlur('deadlift1')}
														onFocus={this.handleFocus('deadlift1')}
													/>
													{this.shouldMarkError('deadlift1') && (
														<Label basic color="red" pointing="above">
															{validationErrors.deadlift1}
														</Label>
													)}
												</Form.Field>
											</Form.Group>
										)}
									</Form>
								</Modal.Content>
								<Modal.Actions>
									<Button color="orange" basic onClick={this.close}>
										Cancel
									</Button>
									{mode === 'create' && (
										<Button
											color="orange"
											disabled={!isEnabled}
											onClick={async e => {
												e.preventDefault()
												if (!creating) {
													const obj = Object.keys(this.state.entrant).reduce((obj, key) => {
														if (this.state.entrant[key]) {
															obj[key] = this.state.entrant[key]
														}
														return obj
													}, {})
													await createEntrant({
														variables: obj,
													})
													this.reset()
												}
											}}
											loading={creating}>
											Save and Add New
										</Button>
									)}
									<Button
										color="orange"
										disabled={!isEnabled}
										onClick={async e => {
											e.preventDefault()
											if (!creating && !updating) {
												const obj = Object.keys(this.state.entrant).reduce((obj, key) => {
													if (this.state.entrant[key]) {
														obj[key] = this.state.entrant[key]
													}
													return obj
												}, {})
												if (mode === 'create' && !creating) {
													await createEntrant({
														variables: obj,
													})
												} else {
													obj.id = this.props.entrant.id
													await updateEntrant({
														variables: obj,
													})
												}
												this.close()
											}
										}}
										loading={creating || updating}>
										{mode === 'update' ? 'Save' : 'Save and Close'}
									</Button>
								</Modal.Actions>
							</Modal>
						)}
					</Mutation>
				)}
			</Mutation>
		)
	}
}
