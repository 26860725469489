import gql from 'graphql-tag'

export default gql(`
query GetUserProfile($username: String!) {
	getUserProfile(username: $username) {
		__typename
		username
		fullname
		gender
		dateOfBirth
		updatedAt
	}
}`)
