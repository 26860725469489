import React, { Component } from 'react'
import { Button, Form, Grid, Label, Input, Dropdown, Segment, Header, Icon } from 'semantic-ui-react'
import { graphql } from 'react-apollo'
import DatePicker from 'react-datepicker'
import SemanticDatePicker from '../components/SemanticDatePicker'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css'
import '../css/createuserprofile.css'
import CreateUserProfileMutation from '../queries/CreateUserProfileMutation'

const genderOptions = [{ key: 'f', text: 'Female', value: 'female' }, { key: 'm', text: 'Male', value: 'male' }]

const formHeaderStyle = {
	margin: 0,
	padding: 0,
	verticalAlign: 'bottom',
	display: 'table-cell',
}

const segmentStyle = {
	margin: '0 0 5px',
	padding: 0,
}

function querystring(name, url = window.location.href) {
	name = name.replace(/[[]]/g, '\\$&')

	const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)', 'i')
	const results = regex.exec(url)

	if (!results) {
		return null
	}
	if (!results[2]) {
		return ''
	}

	return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

class CreateUserProfile extends Component {
	constructor(props) {
		super(props)
		this.state = this.getInitialState()
	}

	getInitialState = () => {
		const initialState = {
			loading: false,
			created: false,
			userProfile: {
				fullname: '',
				gender: '',
				dateOfBirth: null,
			},
			touched: {
				fullname: false,
				gender: false,
				dateOfBirth: false,
			},
			validationErrors: {
				fullname: '',
				gender: '',
				dateOfBirth: '',
			},
			focused: {
				fullname: false,
				gender: false,
				dateOfBirth: false,
			},
		}
		return initialState
	}

	reset = () => {
		this.setState(this.getInitialState())
	}

	handleDOBChange(datetime) {
		const { userProfile } = this.state

		userProfile.dateOfBirth = datetime.unix()
		this.setState({ userProfile })

		this.setState({
			validationErrors: { ...this.state.validationErrors, dateOfBirth: this.validateField('dateOfBirth', datetime) },
		})
	}

	handleChange = (event, { name, value }) => {
		const { userProfile } = this.state

		userProfile[name] = value
		this.setState({ userProfile })

		this.setState({
			validationErrors: { ...this.state.validationErrors, [name]: this.validateField(name, value) },
		})
	}

	handleBlur = name => async event => {
		const validationError = this.validateField(name, this.state.userProfile[name])
		this.setState({
			touched: { ...this.state.touched, [name]: true },
			validationErrors: { ...this.state.validationErrors, [name]: validationError },
			focused: { ...this.state.focused, [name]: false },
		})
	}

	handleFocus = name => async event => {
		if (!this.state.touched[name]) {
			this.setState({
				focused: { ...this.state.focused, [name]: true },
			})
		}
	}

	shouldMarkError = field => {
		return this.state.touched[field] && this.state.validationErrors[field] !== ''
	}

	validateField(name, value) {
		const validations = {
			fullname: input => {
				return input.length < 3 ? 'Please enter your full name' : ''
			},
			gender: input => {
				return input.length === 0 ? 'Please select your gender' : ''
			},
			dateOfBirth: input => {
				return input === null ? 'Please add your date of birth' : ''
			},
		}
		return validations[name](value)
	}

	createUserProf = async e => {
		e.stopPropagation()
		e.preventDefault()

		const { history, userData, createUserProfile } = this.props
		const { userProfile } = this.state

		try {
			await createUserProfile({
				...userProfile,
				username: userData.username,
			})
			const redirect = querystring('redirect')
			history.push(redirect ? redirect : '/')
		} catch (err) {
			console.log(err)
		}
	}

	render() {
		const { userProfile, loading, validationErrors, focused, touched } = this.state
		const isEnabled = userProfile.fullname && userProfile.gender && userProfile.dateOfBirth && !Object.keys(validationErrors).some(x => validationErrors[x])
		return (
			<div className="CreateUserProfile">
				<div className="create-org-form">
					<style>{`
              body > div,
              body > div > div,
              body > div > div > div.createorg-form {
				height: 100%;
			  }
            `}</style>
					<Grid textAlign="center" style={{ height: '100%' }} verticalAlign="middle">
						<Grid.Column style={{ maxWidth: 450 }} textAlign="left">
							<Segment basic clearing style={segmentStyle}>
								<Header style={formHeaderStyle}>
									<div>Create Profile</div>
								</Header>
							</Segment>
							<Form size="large" loading={loading} className="fluid" autoComplete="off">
								<Form.Field error={this.shouldMarkError('fullname')}>
									<Input
										type="text"
										icon={
											!this.shouldMarkError('fullname') && touched['fullname'] && !focused['fullname'] ? (
												<Icon name="checkmark" color="green" />
											) : this.shouldMarkError('fullname') && !focused['fullname'] ? (
												<Icon name="remove" color="red" />
											) : (
												'id card outline'
											)
										}
										iconPosition="left"
										placeholder="Full name"
										name="fullname"
										value={userProfile.fullname}
										autoComplete="nope"
										onChange={this.handleChange}
										onBlur={this.handleBlur('fullname')}
										onFocus={this.handleFocus('fullname')}
									/>
									{this.shouldMarkError('fullname') && (
										<Label basic color="red" pointing="above">
											{validationErrors.fullname}
										</Label>
									)}
								</Form.Field>
								<Form.Field error={this.shouldMarkError('gender')}>
									<Dropdown
										placeholder="Select Gender"
										fluid
										search
										selection
										options={genderOptions}
										name="gender"
										value={userProfile.gender}
										onChange={this.handleChange}
										onBlur={this.handleBlur('gender')}
										onFocus={this.handleFocus('gender')}
									/>
									{this.shouldMarkError('gender') && (
										<Label basic color="red" pointing="above">
											{validationErrors.gender}
										</Label>
									)}
								</Form.Field>
								<Form.Field error={this.shouldMarkError('dateOfBirth')}>
									<DatePicker
										customInput={<SemanticDatePicker icon="calendar outline" placeholderText="Date of birth" />}
										name="dateOfBirth"
										selected={userProfile.dateOfBirth ? moment(userProfile.dateOfBirth * 1000) : userProfile.dateOfBirth}
										onChange={this.handleDOBChange.bind(this)}
										dateFormat="LL"
										maxDate={moment()}
										className="flex"
										showMonthDropdown
										showYearDropdown
										dropdownMode="select"
										//onBlur={this.handleBlur('startDateTime')}
									/>
									{this.shouldMarkError('dateOfBirth') && (
										<Label basic color="red" pointing="above">
											{validationErrors.dateOfBirth}
										</Label>
									)}
								</Form.Field>
								<Button color="teal" disabled={!isEnabled} fluid size="large" onClick={this.createUserProf}>
									Complete Registration
								</Button>
							</Form>
						</Grid.Column>
					</Grid>
				</div>
			</div>
		)
	}
}

export default graphql(CreateUserProfileMutation, {
	options: {},
	props: props => ({
		createUserProfile: userProfile => {
			return props.mutate({
				variables: userProfile,
			})
		},
	}),
})(CreateUserProfile)
