import gql from 'graphql-tag'

export default gql`
	subscription UserEventsChangeSubscription($username: String!) {
		userEventsChange(username: $username) {
			__typename
			id
			eventName
			eventStatus
			placeId
			location
			startDateTime
			days
			weighIn
			capacity
			notes
			equipmentOptions
			divisions {
				powerlifting
				pushpull
				benchonly
				deadliftonly
			}
			federation
			liveStatus {
				day
				lift
				flight
				attempt
				lifter
				status
			}
		}
	}
`
