const events = ['powerlifting', 'pushpull', 'benchonly', 'deadliftonly']

const eventTypes = {
	powerlifting: 'PL',
	pushpull: 'PP',
	benchonly: 'BP',
	deadliftonly: 'DL',
	error: 'ERR',
}

const flights = [
	{
		key: 'A',
		text: 'A',
		value: 'A',
	},
	{
		key: 'B',
		text: 'B',
		value: 'B',
	},
	{
		key: 'C',
		text: 'C',
		value: 'C',
	},
	{
		key: 'D',
		text: 'D',
		value: 'D',
	},
	{
		key: 'E',
		text: 'E',
		value: 'E',
	},
	{
		key: 'F',
		text: 'F',
		value: 'F',
	},
	{
		key: 'G',
		text: 'G',
		value: 'G',
	},
]

const lifts = ['squat', 'bench', 'deadlift']

export const getEvents = () => events

export const getEventsMapping = event => eventTypes[event]

export const getFlights = () => flights

export const getFirstFlight = () => flights[0]

export const getNextFlight = flight => {
	return flights[flights.map(flight => flight.value).indexOf(flight) + 1]
}

export const getLifts = () => lifts

export const getNextLift = lift => lifts[lifts.indexOf(lift) + 1]
