import React, { Component } from 'react'
import '../../css/events.css'
import { Container, Header } from 'semantic-ui-react'
import EntrantsList from './EntrantsList'
import { getFederationData } from '../../data/Federations'
import { getDivision } from '../../utils/entrants'
import { getEventType } from '../../utils/events'

class EventRegistration extends Component {
	state = {
		entrantModalOpen: false,
	}

	openEntrantModal = entrant => {
		this.setState({
			editingEntrant: entrant,
			entrantModalOpen: true,
		})
	}

	closeEntrantModal = () => {
		const newState = this.state
		newState.entrantModalOpen = false
		delete newState.editingEntrant
		this.setState(newState)
	}

	render() {
		const { entrants, event } = this.props

		const { weightClasses } = getFederationData(event.federation)
		entrants.forEach(entrant => {
			entrant.events = getEventType(event, entrant)
			entrant.weightClass = weightClasses.find(weightClass => weightClass.gender === entrant.gender && entrant.weight <= weightClass.max).class
			entrant.max = weightClasses.find(weightClass => weightClass.gender === entrant.gender && entrant.weight <= weightClass.max).max
			entrant.division = getDivision(entrant, event)
		})
		entrants.sort((a, b) => {
			if (a.flight !== b.flight) return a.flight < b.flight ? -1 : 1
			if (a.gender !== b.gender) return a.gender < b.gender ? -1 : 1
			if (a.max !== b.max) return a.max - b.max
			if (a['squat1'] && b['squat1'] && a['squat1'].weight !== b['squat1'].weight) return a['squat1'].weight - b['squat1'].weight
			if (a['squat1'] && !b['squat1']) {
				return -1
			}
			if (!a['squat1'] && b['squat1']) {
				return 1
			}
			return a.weight - b.weight
		})

		return (
			<Container>
				<Header size="medium">Entrants</Header>
				<EntrantsList
					entrants={this.props.entrants}
					event={this.props.event}
					loading={this.props.loading || this.props.loadingEntrants}
					openEntrantModal={this.props.openEntrantModal}
				/>
			</Container>
		)
	}
}

export default EventRegistration
