import React, { Component } from 'react'
import { Query } from 'react-apollo'
import '../css/events.css'
import { Container, Loader } from 'semantic-ui-react'
import { withMenu } from '../containers/Menu'
import AllEvents from '../queries/AllEvents'
import EventsChangeSubscription from '../queries/EventsChangeSubscription'
import EventItems from './components/EventItems'
import moment from 'moment'

class Events extends Component {
	handleRefresh = () => {
		this.state.refetch()
	}

	setLoading = loading => {
		this.setState({
			loading: loading,
		})
	}

	setRefetch = refetch => {
		this.setState({
			refetch: refetch,
		})
	}

	render = () => {
		return (
			<Query query={AllEvents} variables={{ eventStatus: 'active' }} fetchPolicy="cache-and-network">
				{({ data, error, loading, refetch, subscribeToMore }) => {
					if (error) {
						return <Container>{error}</Container>
					}
					return (
						<Container>
							{!loading && (!data || !data.allEvents || data.allEvents.length === 0) && (
								<span>
									<br />
									No events found.
									<br />
								</span>
							)}
							{loading && (!data || !data.allEvents || data.allEvents.length === 0) && <Loader active>Loading Events</Loader>}
							{data && data.allEvents && data.allEvents.length > 0 && (
								<EventItems
									{...this.props}
									events={data.allEvents.filter(
										event => event.liveStatus || moment().isBefore(moment(event.startDateTime * 1000).add(1, 'hours'))
									)}
									loading={loading}
									refetch={refetch}
									setLoading={this.setLoading}
									setRefetch={this.setRefetch}
									subscribeToEventChanges={() => {
										subscribeToMore({
											document: EventsChangeSubscription,
											variables: { eventStatus: 'active' },
											updateQuery: (
												prev,
												{
													subscriptionData: {
														data: { eventChange },
													},
												}
											) => ({
												...prev,
												event: [...prev.event.filter(event => event.id !== eventChange.id), eventChange],
											}),
										})
									}}
									viewEvent={this.viewEvent}
									viewMode="card"
								/>
							)}
						</Container>
					)
				}}
			</Query>
		)
	}
}

export default withMenu(Events)
