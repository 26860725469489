import React, { Component } from 'react'
import { Icon } from 'semantic-ui-react'
import { Switch } from 'react-router-dom'
import { withApollo } from 'react-apollo'
import { withAuthenticator } from './auth/Authenticator'
import CookieConsent from 'react-cookie-consent'
import AuthenticatedNoProfileRoute from './components/AuthenticatedNoProfileRoute'
import AuthenticatedRoute from './components/AuthenticatedRoute'
import UnauthenticatedRoute from './components/UnauthenticatedRoute'
import Forgot from './containers/Forgot'
import Login from './containers/Login'
import Signup from './containers/Signup'
import Home from './containers/Home'
import './App.css'
import CreateEvent from './events/CreateEvent'
import CreateUserProfile from './containers/CreateUserProfile'
import Events from './events/Events'
import Result from './events/Result'
import Results from './events/Results'
import Event from './events/Event'
import UserEvents from './events/UserEvents'

class PowerlifterPro extends Component {
	state = {
		userCountry: '',
		fullScreen: false,
	}

	escFunction = event => {
		if (event.keyCode === 27 && this.state.fullScreen) {
			event.preventDefault()
			this.setState({
				fullScreen: false,
			})
			return false
		}
	}
	componentDidMount = () => {
		//const { cookies } = this.props
		//cookies.remove('accepts-cookies')
		document.addEventListener('keydown', this.escFunction, false)
	}
	componentWillUnmount = () => {
		document.removeEventListener('keydown', this.escFunction, false)
	}

	setFullScreen = () => {
		this.setState({
			fullScreen: true,
		})
	}

	render() {
		const childProps = {
			client: this.props.client,
			getUserPlatforms: this.props.getUserPlatforms,
			fullScreen: this.state.fullScreen,
			history: this.props.history,
			isAuthenticated: this.props.authenticated,
			isAuthenticatedUser: this.props.isAuthenticatedUser,
			signIn: this.props.signIn,
			signOut: this.props.signOut,
			setFullScreen: this.setFullScreen,
			updateAuthState: this.props.updateAuthState,
			userPlatforms: this.props.userPlatforms,
			userProfile: this.props.userProfile,
			userData: this.props.authData,
		}
		return (
			<div className="App container full">
				<Switch>
					<UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
					<UnauthenticatedRoute path="/login/:username" exact component={Login} props={childProps} />
					<UnauthenticatedRoute path="/forgot" exact component={Forgot} props={childProps} />
					<UnauthenticatedRoute path="/forgot/:username" exact component={Forgot} props={childProps} />
					<UnauthenticatedRoute path="/signup" exact component={Signup} props={childProps} />
					<UnauthenticatedRoute path="/event/:event" exact component={Event} props={childProps} />
					<UnauthenticatedRoute path="/events" exact component={Events} props={childProps} />
					<UnauthenticatedRoute path="/result/:event" exact component={Result} props={childProps} />
					<UnauthenticatedRoute path="/results" exact component={Results} props={childProps} />
					<AuthenticatedRoute path="/createEvent" exact component={CreateEvent} props={childProps} />
					<AuthenticatedRoute path="/userEvents" exact component={UserEvents} props={childProps} />
					<AuthenticatedNoProfileRoute path="/createUserProfile" exact component={CreateUserProfile} props={childProps} />
					<AuthenticatedRoute path="/home" exact component={Home} props={childProps} />
					<UnauthenticatedRoute path="/" exact component={Home} props={childProps} />
				</Switch>
				<CookieConsent
					buttonText={<Icon size="large" name="close"></Icon>}
					contentStyle={{ textAlign: 'center', flex: '0 0 auto' }}
					buttonStyle={{ background: 'rgba(35, 35, 35, 0.0', color: 'white', margin: '15px 0', padding: '5px 0' }}
					style={{ background: 'rgba(35, 35, 35, 0.8', justifyContent: 'center' }}>
					This website uses cookies to enhance the user experience.
				</CookieConsent>
			</div>
		)
	}
}

export default withApollo(withAuthenticator(PowerlifterPro))
