import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Menu, Visibility, Icon, Container, Button, Dropdown } from 'semantic-ui-react'

const minSize = 698

export function withMenu(Cmponent) {
	return class extends Component {
		state = {
			menuProps: {
				style: {
					backgroundColor: '#fff',
					border: 'none',
					borderRadius: 0,
					boxShadow: 'none',
					padding: '0',
					borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
				},
			},
			menuOptionsProps: {
				style: {
					display: 'flex',
				},
			},
			hamburgerMenuProps: {
				style: {
					display: 'none',
				},
			},
		}

		stickTopMenu = () => {
			const { menuProps } = this.state
			menuProps.fixed = 'top'
			this.setState({ menuProps: menuProps })
		}

		unStickTopMenu = () => {
			const { menuProps } = this.state
			delete menuProps.fixed
			this.setState({ menuProps: menuProps })
		}

		handleUpdate = (e, { calculations }) => {
			const { menuOptionsProps, hamburgerMenuProps } = this.state
			if (calculations.width < minSize && menuOptionsProps.style.display === 'flex') {
				menuOptionsProps.style = { display: 'none' }
				hamburgerMenuProps.style = { display: 'flex' }
				this.setState({ menuOptionsProps: menuOptionsProps, hamburgerMenuProps: hamburgerMenuProps })
			} else if (calculations.width >= minSize && menuOptionsProps.style.display === 'none') {
				menuOptionsProps.style = { display: 'flex' }
				hamburgerMenuProps.style = { display: 'none' }
				this.setState({ menuOptionsProps: menuOptionsProps, hamburgerMenuProps: hamburgerMenuProps })
			}
		}

		reload = () => {
			window.location.reload(false)
		}

		setActiveTab = tab => {
			this.setState({
				activeTab: tab,
			})
		}

		render() {
			const { menuProps, menuOptionsProps, hamburgerMenuProps } = this.state
			const { isAuthenticated, location, signOut } = this.props
			return (
				<Visibility
					onBottomPassed={this.stickTopMenu}
					onBottomVisible={this.unStickTopMenu}
					once={false}
					onUpdate={this.handleUpdate}
					fireOnMount={true}>
					<Menu borderless style={this.props.fullScreen ? { display: 'none' } : menuProps.style}>
						<Container fluid={this.props.fullScreen}>
							<Menu.Item>
								<Icon name="trophy" size="large" />
							</Menu.Item>
							<Menu.Menu position="right" {...menuOptionsProps}>
								<Menu.Item as={Link} to="/events">
									Events
								</Menu.Item>
								<Menu.Item as={Link} to="/results">
									Results
								</Menu.Item>
								<Menu.Item as={Link} to="/createEvent">
									Create Event
								</Menu.Item>
								<Menu.Item>
									{isAuthenticated && (
										<Dropdown icon={<Icon name="user circle outline" size="large" />} item>
											<Dropdown.Menu>
												<Dropdown.Item as={Link} to="/createEvent">
													Create Event
												</Dropdown.Item>
												<Dropdown.Item as={Link} to="/userEvents">
													Manage Events
												</Dropdown.Item>
												<Dropdown.Divider />
												<Dropdown.Item onClick={signOut}>Logout</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									)}
									{!isAuthenticated && (
										<Button basic as={Link} to={`/login?redirect=${location.pathname}`}>
											Login
										</Button>
									)}
								</Menu.Item>
							</Menu.Menu>
							<Menu.Menu position="right" {...hamburgerMenuProps}>
								<Dropdown item icon={<Icon name="sidebar" size="large" />}>
									<Dropdown.Menu>
										<Dropdown.Item as={Link} to="/events">
											Events
										</Dropdown.Item>
										<Dropdown.Item as={Link} to="/results">
											Results
										</Dropdown.Item>
										<Dropdown.Divider />
										<Dropdown.Item as={Link} to="/createEvent">
											Create Event
										</Dropdown.Item>
										<Dropdown.Item as={Link} to="/userEvents">
											Manage Events
										</Dropdown.Item>
										<Dropdown.Divider />
										<Dropdown.Item>
											{isAuthenticated && (
												<Button basic onClick={signOut}>
													Sign Out
												</Button>
											)}
											{!isAuthenticated && (
												<Button basic as={Link} to={`/login?redirect=${location.pathname}`}>
													Login
												</Button>
											)}
										</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</Menu.Menu>
						</Container>
					</Menu>
					<Container fluid={this.props.fullScreen} style={{ height: '100%' }}>
						<Cmponent {...this.props} />
					</Container>
				</Visibility>
			)
		}
	}
}
