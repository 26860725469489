import gql from 'graphql-tag'

export default gql(`
mutation(
	$event: String!,
	$status: String,
	$name: String!,
	$age: Int,
	$gender: String!,
	$weight: Float,
	$equipment: String,
	$rackHeight: String,
	$racksInOut: String,
	$benchHeight: String,
	$faceSaverHeight: String,
	$squat1: AttemptInput,
	$bench1: AttemptInput,
	$deadlift1: AttemptInput,
	$flight: String,
	$day: Int) {
  createEntrant(
	event: $event
	status: $status
	name: $name
	age: $age
	gender: $gender
	weight: $weight
	equipment: $equipment
	rackHeight: $rackHeight
	racksInOut: $racksInOut
	benchHeight: $benchHeight
	faceSaverHeight: $faceSaverHeight
	squat1: $squat1
	bench1: $bench1
	deadlift1: $deadlift1
	flight: $flight
	day: $day
  ) {
	__typename
	id
	event
	status
	name
	age
	gender
	weight
	equipment
	rackHeight
	racksInOut
	benchHeight
	faceSaverHeight
	squat1 {
		status
		weight
	}
	squat2 {
		status
		weight
	}
	squat3 {
		status
		weight
	}
	squat4 {
		status
		weight
	}
	bench1 {
		status
		weight
	}
	bench2 {
		status
		weight
	}
	bench3 {
		status
		weight
	}
	bench4 {
		status
		weight
	}
	deadlift1 {
		status
		weight
	}
	deadlift2 {
		status
		weight
	}
	deadlift3 {
		status
		weight
	}
	deadlift4 {
		status
		weight
	}
	flight
	day
	}
}`)
