import gql from 'graphql-tag'

export default gql`
	query GetEvent($id: ID!) {
		event(id: $id) {
			__typename
			id
			username
			eventName
			eventStatus
			placeId
			location
			startDateTime
			days
			weighIn
			capacity
			notes
			equipmentOptions
			divisions {
				powerlifting
				pushpull
				benchonly
				deadliftonly
			}
			federation
			liveStatus {
				day
				lift
				flight
				attempt
				lifter
				status
			}
			image
		}
	}
`
