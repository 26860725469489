import gql from 'graphql-tag'

export default gql`
	subscription EventChangeSubscription($id: ID!) {
		eventChange(id: $id) {
			__typename
			id
			eventName
			eventStatus
			placeId
			location
			startDateTime
			days
			weighIn
			capacity
			notes
			equipmentOptions
			divisions {
				powerlifting
				pushpull
				benchonly
				deadliftonly
			}
			federation
			liveStatus {
				day
				lift
				flight
				attempt
				lifter
				status
			}
			image
		}
	}
`
