import React, { Component } from 'react'
import { Mutation } from 'react-apollo'
import { Input, Table } from 'semantic-ui-react'
import { withAuthenticator } from '../../auth/Authenticator'
import LogAttemptMutation from '../../queries/LogAttemptMutation'
import '../../css/events.css'

class AttemptCell extends Component {
	constructor(props) {
		super(props)
		this.state = this.getInitialState(props.attempt, props.entrant, props.lift)
	}

	componentDidUpdate = prevProps => {
		const { attempt, entrant, lift } = this.props
		if (
			(!prevProps.entrant[`${lift}${attempt}`] && entrant[`${lift}${attempt}`]) ||
			(prevProps.entrant[`${lift}${attempt}`] &&
				entrant[`${lift}${attempt}`] &&
				prevProps.entrant[`${lift}${attempt}`].weight !== entrant[`${lift}${attempt}`].weight)
		) {
			this.setState({
				attemptEdit: entrant[`${lift}${attempt}`] ? entrant[`${lift}${attempt}`].weight : '',
			})
		}
	}

	getInitialState = (attempt, entrant, lift) => ({
		attemptEdit: entrant[`${lift}${attempt}`] ? entrant[`${lift}${attempt}`].weight : '',
	})

	handleEditableCellChange = (e, { value }) => {
		this.setState({ attemptEdit: value })
	}

	render() {
		const { attempt, editing, entrant, event, handleEditableCellExit, isAuthenticatedUser, lift, setEditableCell } = this.props
		const { attemptEdit } = this.state
		return (
			<Table.Cell
				textAlign="center"
				style={editing ? { padding: '0px' } : {}}
				onClick={e => {
					e.preventDefault()
					console.log('click')
					if (
						isAuthenticatedUser(event.username) &&
						event.liveStatus &&
						event.liveStatus === 'active' &&
						entrant[`${lift}${attempt - 1}`] &&
						entrant[`${lift}${attempt - 1}`].status !== 'notattempted' &&
						(!entrant[`${lift}${attempt}`] || entrant[`${lift}${attempt}`].status === 'notattempted')
					) {
						console.log('setting editable cell')
						setEditableCell(entrant, `${lift}${attempt}`)
					}
				}}
				positive={entrant[`${lift}${attempt}`] && entrant[`${lift}${attempt}`].status === 'goodlift'}
				negative={entrant[`${lift}${attempt}`] && entrant[`${lift}${attempt}`].status === 'nolift'}>
				{editing ? (
					<Mutation mutation={LogAttemptMutation} key={`${entrant.id}-logAttempt`}>
						{(logAttempt, { loading: logging }) => (
							<Input
								fluid
								loading={logging}
								autoFocus
								onBlur={async e => {
									e.preventDefault()
									handleEditableCellExit(entrant.id, `${lift}${attempt}`)
									if (!isNaN(parseFloat(attemptEdit)) && !logging) {
										await logAttempt({
											variables: {
												id: entrant.id,
												lift: lift,
												number: attempt,
												attempt: {
													weight: parseFloat(attemptEdit),
													status: 'notattempted',
												},
											},
										})
									}
								}}
								onChange={this.handleEditableCellChange}
								onKeyDown={e => {
									if (e.keyCode === 13) {
										e.target.blur()
									}
								}}
								type="text"
								value={attemptEdit}
								autoComplete="nope"
							/>
						)}
					</Mutation>
				) : entrant[`${lift}${attempt}`] ? (
					entrant[`${lift}${attempt}`].weight
				) : (
					''
				)}
			</Table.Cell>
		)
	}
}

export default withAuthenticator(AttemptCell)
