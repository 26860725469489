import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'semantic-ui-react'

export default class SemanticDatePicker extends React.Component {
	render() {
		return (
			<Input icon={this.props.icon} iconPosition="left" placeholder={this.props.placeholderText} onClick={this.props.onClick} value={this.props.value} />
		)
	}
}

SemanticDatePicker.propTypes = {
	onClick: PropTypes.func,
	value: PropTypes.string,
}
