import React from 'react'
import { Button, Form, Label, Input, Icon, Grid } from 'semantic-ui-react'
import { getFederationData } from '../../data/Federations'
import '../../css/createevent.css'

const daysOptions = [
	{ key: '1', text: '1 Day', value: 1 },
	{ key: '2', text: '2 Days', value: 2 },
	{ key: '3', text: '3 Days', value: 3 },
	{ key: '4', text: '4 Days', value: 4 },
	{ key: '5', text: '5 Days', value: 5 },
	{ key: '6', text: '6 Days', value: 6 },
	{ key: '7', text: '7 Days', value: 7 },
]
const equipmentOpts = [{ key: 'raw', text: 'Raw', value: 'R' }, { key: 'equipped', text: 'Equipped', value: 'E' }]
const weightInOptions = [{ key: '2', text: '2 Hours', value: 2 }, { key: '24', text: '24 Hours', value: 24 }]

export default class CreateEventSetup extends React.Component {
	constructor(props) {
		super(props)
		this.state = this.getInitialState(props.event)
	}

	addTestData = () => {
		this.setState(
			this.getInitialState({
				...this.state.event,
				capacity: 30,
			})
		)
	}

	clearTestData = () => {
		this.setState(this.getInitialState())
	}

	getInitialState = event => ({
		event: {
			days: event && event.days ? event.days : 1,
			federation: event.federation,
			weighIn: event.weighIn,
			divisions: event.divisions,
			equipmentOptions: event.equipmentOptions,
			capacity: event && event.capacity ? event.capacity : 0,
			notes: event && event.notes ? event.notes : '',
		},
		touched: {
			divisions: false,
			equipmentOptions: false,
			capacity: false,
			notes: true,
		},
		validationErrors: {
			divisions: '',
			equipmentOptions: '',
			capacity: '',
			notes: '',
		},
	})

	handleBlur = name => async e => {
		this.setState({
			touched: { ...this.state.touched, [name]: true },
			validationErrors: { ...this.state.validationErrors, [name]: this.validateField(name, this.state.event[name]) },
		})
	}

	handleChange = (e, { name, value }) => {
		const { event } = this.state

		if (name === 'capacity') {
			try {
				event[name] = parseInt(value,10)
			} catch (err) {
				event[name] = value
			}
		} else {
			event[name] = value
		}

		this.setState({
			event: Object.keys(event).reduce((cleanedEvent, key) => {
				if (typeof event[key] !== 'undefined' && event[key] !== null && event[key] !== '') {
					cleanedEvent[key] = event[key]
				}
				return cleanedEvent
			}, {}),
		})
		this.setState({
			touched: { ...this.state.touched, [name]: true },
			validationErrors: { ...this.state.validationErrors, [name]: this.validateField(name, value) },
		})
	}

	handleCheckbox = (e, { name, value }) => {
		const { event } = this.state

		if (event[name].includes(value)) {
			event[name] = event[name].filter(element => element !== value)
		} else {
			event[name].push(value)
		}

		this.setState({
			event,
			touched: { ...this.state.touched, [name]: true },
			validationErrors: { ...this.state.validationErrors, [name]: this.validateField(name, event[name]) },
		})
	}

	handleDivisonChange = (e, { name, value }) => {
		const { event } = this.state

		if (typeof event.divisions[value] !== 'undefined') {
			delete event.divisions[value]
		} else {
			event.divisions[value] = getFederationData(event.federation).divisions[value]
		}

		this.setState({
			event,
			touched: { ...this.state.touched, [name]: true },
			validationErrors: { ...this.state.validationErrors, [name]: this.validateField(name, event[name]) },
		})
	}

	handleDaysChange = (e, { value }) => {
		const { event } = this.state

		event.days = value

		this.setState({ event })
	}

	handleWeightInChange = (e, { value }) => {
		const { event } = this.state

		event.weighIn = value

		this.setState({ event })
	}

	shouldMarkError = field => {
		return this.state.touched[field] && this.state.validationErrors[field] !== ''
	}

	validateField(name, value) {
		const validations = {
			divisions: input => {
				return Object.keys(input).length === 0 ? 'Please choose at least one event type' : ''
			},
			equipmentOptions: input => {
				return input.length === 0 ? 'Please choose at least one equipment option' : ''
			},
			capacity: input => {
				return !input || input < 1 || isNaN(input) ? 'Please enter a lifter capacity' : ''
			},
			weighIn: input => {
				return !input || isNaN(input) ? 'Please select a weigh in time' : ''
			},
			notes: input => {
				return ''
			},
			eventType: input => {
				return ''
			},
		}
		return validations[name](value)
	}

	render() {
		const { nextStep, stepBack } = this.props
		const { event, validationErrors } = this.state
		const isEnabled =
			event.equipmentOptions.length > 0 &&
			Object.keys(event.divisions).length > 0 &&
			event.capacity > 0 &&
			!Object.keys(validationErrors).some(x => validationErrors[x])
		return (
			<Form size="large" className="attached fluid" autoComplete="off">
				<Grid>
					<Grid.Row columns={2} style={{ paddingBottom: 0 }}>
						<Grid.Column>
							<Form.Field>
								<label># Days</label>
								<Form.Select
									placeholder="Competition length"
									fluid
									selection
									options={daysOptions}
									name="days"
									value={event.days}
									onChange={this.handleDaysChange}
								/>
							</Form.Field>
							<Form.Field>
								<label>Capacity</label>
								<Input
									icon="users"
									iconPosition="left"
									type="text"
									fluid
									placeholder="Capacity"
									name="capacity"
									focus={true}
									value={event.capacity}
									autoComplete="nope"
									onChange={this.handleChange}
									onBlur={this.handleBlur('capacity')}
								/>
								{this.shouldMarkError('capacity') && (
									<Label basic color="red" pointing="above">
										{validationErrors.capacity}
									</Label>
								)}
							</Form.Field>
						</Grid.Column>
						<Grid.Column>
							<Form.Group unstackable inline>
								{weightInOptions.map((option, index) => (
									<Form.Field key={option.key}>
										<label>{index === 0 ? 'Weigh In' : ''}</label>
										<Form.Radio
											label={option.text}
											name="weighIn"
											value={option.value}
											checked={event.weighIn === option.value}
											onChange={this.handleChange}
										/>
									</Form.Field>
								))}
							</Form.Group>
							<Form.Group widths="equal">
								<Form.Field>
									<label>Event Types</label>
									<Form.Checkbox
										label="Powerlifting"
										name="divisions"
										value="powerlifting"
										onClick={this.handleDivisonChange}
										checked={Object.keys(event.divisions).includes('powerlifting')}
									/>
									<Form.Checkbox
										label="Push/Pull"
										name="divisions"
										value="pushpull"
										onClick={this.handleDivisonChange}
										checked={Object.keys(event.divisions).includes('pushpull')}
									/>
									<Form.Checkbox
										label="Bench only"
										name="divisions"
										value="benchonly"
										onClick={this.handleDivisonChange}
										checked={Object.keys(event.divisions).includes('benchonly')}
									/>
									<Form.Checkbox
										label="Deadlift only"
										name="divisions"
										value="deadliftonly"
										onClick={this.handleDivisonChange}
										checked={Object.keys(event.divisions).includes('deadliftonly')}
									/>
									{this.shouldMarkError('eventOptions') && (
										<Label basic color="red" pointing="above">
											{validationErrors.eventOptions}
										</Label>
									)}
								</Form.Field>
								<Form.Field>
									<label>Equipment Options</label>
									{equipmentOpts.map((option, index) => (
										<Form.Checkbox
											key={option.value}
											label={option.text}
											name="equipmentOptions"
											value={option.value}
											onClick={this.handleCheckbox}
											checked={event.equipmentOptions.includes(option.value)}
										/>
									))}
									{this.shouldMarkError('equipmentOptions') && (
										<Label basic color="red" pointing="above">
											{validationErrors.equipmentOptions}
										</Label>
									)}
								</Form.Field>
							</Form.Group>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row style={{ paddingTop: 0 }}>
						<Grid.Column>
							<Form.TextArea
								label="Event Notes"
								placeholder="Add some notes for competitors"
								name="notes"
								value={event.notes}
								autoComplete="nope"
								onChange={this.handleChange}
							/>
							<Button
								animated
								color="grey"
								floated="left"
								size="large"
								onClick={async e => {
									e.preventDefault()
									stepBack({ event })
								}}>
								<Button.Content visible>Back</Button.Content>
								<Button.Content hidden>
									<Icon name="arrow left" />
								</Button.Content>
							</Button>
							{process.env.NODE_ENV === 'development' && (
								<Button
									color="grey"
									size="large"
									floated="left"
									onClick={async e => {
										e.preventDefault()
										this.clearTestData()
									}}>
									Reset
								</Button>
							)}
							<Button
								animated
								color="orange"
								disabled={!isEnabled}
								floated="right"
								size="large"
								onClick={async e => {
									e.preventDefault()
									nextStep({ event })
								}}>
								<Button.Content visible>Next</Button.Content>
								<Button.Content hidden>
									<Icon name="arrow right" />
								</Button.Content>
							</Button>
							{process.env.NODE_ENV === 'development' && (
								<Button
									color="orange"
									size="large"
									floated="right"
									onClick={async e => {
										e.preventDefault()
										this.addTestData()
									}}>
									Test
								</Button>
							)}
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Form>
		)
	}
}
