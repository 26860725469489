import React from 'react'
import { withRouter, Route } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import appSyncConfig from './AppSync'
import { ApolloProvider } from 'react-apollo'
import { Rehydrated } from 'aws-appsync-react'
import { ApolloLink } from 'apollo-link'
import { onError } from 'apollo-link-error'
//import { withClientState } from 'apollo-link-state'
import AWSAppSyncClient, { createAppSyncLink } from 'aws-appsync'
import PowerlifterPro from './PowerlifterPro'
import './fontello/css/plp.css'

// const stateLink = createLinkWithCache(cache =>
// 	withClientState({
// 		cache,
// 		resolvers: {},
// 		defaults: {
// 			networkStatus: {
// 				__typename: 'NetworkStatus',
// 				isConnected: false,
// 			},
// 		},
// 	})
// )

const appSyncLink = createAppSyncLink({
	url: appSyncConfig.graphqlEndpoint,
	region: appSyncConfig.region,
	auth: {
		type: appSyncConfig.authenticationType,
		credentials: async () => await Auth.currentCredentials(),
	},
	disableOffline: true,
})

const errorlink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors)
		graphQLErrors.map(({ message, locations, path }) => console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`))

	if (networkError) console.log(`[Network error]: ${networkError}`)
})

const link = ApolloLink.from([appSyncLink, errorlink])

const client = new AWSAppSyncClient({}, { link })

const App = () => <Route path="/" render={props => <PowerlifterPro {...props} />} />

const withProvider = () => (
	<ApolloProvider client={client}>
		<Rehydrated>
			<App />
		</Rehydrated>
	</ApolloProvider>
)

export default withRouter(withProvider)
