import React, { Component } from 'react'
import { Mutation } from 'react-apollo'
import { Dropdown, Menu, Table } from 'semantic-ui-react'
import AttemptCell from './AttemptCell'
import ChangeFlightMutation from '../../queries/ChangeFlightMutation'
import { getFederationData } from '../../data/Federations'
import { getEventsMapping, getFlights } from '../../data/Constants'
import { getBestSquat, getBestBench, getBestDeadlift, getSubTotal, getTotal, getResult } from '../../utils/entrants'
import { showFirstLift } from '../../utils/entrantList'
import '../../css/events.css'

export default class EntrantsList extends Component {
	constructor(props) {
		super(props)
		this.state = this.getInitialState(props.liveStatus)
	}

	componentDidUpdate = prevProps => {
		if (
			(!prevProps.liveStatus && this.props.liveStatus) ||
			(prevProps.liveStatus &&
				this.props.liveStatus &&
				(prevProps.liveStatus.day !== this.props.liveStatus.day || prevProps.liveStatus.flight !== this.props.liveStatus.flight))
		) {
			this.setState({
				day: this.props.liveStatus.day,
				flight: this.props.liveStatus.flight,
			})
		}
	}

	getInitialState = liveStatus => ({
		day: liveStatus ? liveStatus.day : null,
		editingCell: false,
		flight: liveStatus ? liveStatus.flight : null,
	})

	setEditableCell = (entrant, cellType) => {
		this.setState({
			editingCell: {
				entrant: entrant.id,
				cellType: cellType,
				value: entrant[cellType] ? entrant[cellType].weight : '',
			},
		})
	}

	setCurrentFlight = flight => {
		this.setState({
			flight: flight,
		})
	}

	handleEditableCellExit = (entrant, cellType) => {
		const { editingCell } = this.state
		if (entrant === editingCell.entrant && cellType === editingCell.cellType) {
			this.setState({
				editingCell: false,
			})
		}
	}

	handleEditableCellChange = (e, { value }) => {
		const { editingCell } = this.state

		editingCell.value = value

		this.setState({ editingCell })
	}

	render() {
		const { entrants, event, liveStatus } = this.props
		const { editingCell } = this.state
		const { fourthAttempts } = getFederationData(event.federation)
		const flights = [
			...new Set(
				entrants.reduce((flghts, entrant) => {
					if (flghts.includes[entrant.flight]) {
						return flghts
					}
					flghts.push(entrant.flight)
					return flghts
				}, [])
			),
		]
		return (
			<Table celled striped singleLine>
				<Table.Header>
					<Table.Row>
						{(!liveStatus || liveStatus.status !== 'done') && <Table.HeaderCell textAlign="center">Fl.</Table.HeaderCell>}
						<Table.HeaderCell>Name</Table.HeaderCell>
						<Table.HeaderCell textAlign="center">Age</Table.HeaderCell>
						<Table.HeaderCell textAlign="center">Div</Table.HeaderCell>
						<Table.HeaderCell textAlign="center">Bwt</Table.HeaderCell>
						<Table.HeaderCell textAlign="center">WtCls</Table.HeaderCell>
						{showFirstLift('squat', entrants, liveStatus) && <Table.HeaderCell textAlign="center">SQ 1</Table.HeaderCell>}
						{liveStatus &&
							((liveStatus.status === 'done' && entrants.find(entrant => entrant['squat1'])) ||
								(liveStatus.status === 'active' && liveStatus.lift === 'squat')) && (
								<Table.HeaderCell textAlign="center">SQ 2</Table.HeaderCell>
							)}
						{liveStatus &&
							((liveStatus.status === 'done' && entrants.find(entrant => entrant['squat1'])) ||
								(liveStatus.status === 'active' && liveStatus.lift === 'squat')) && (
								<Table.HeaderCell textAlign="center">SQ 3</Table.HeaderCell>
							)}
						{fourthAttempts &&
							liveStatus &&
							((liveStatus.status === 'done' && entrants.find(entrant => entrant['squat4'])) ||
								(liveStatus.status === 'active' && liveStatus.lift === 'squat')) && (
								<Table.HeaderCell textAlign="center">SQ 4</Table.HeaderCell>
							)}
						{liveStatus && liveStatus.status === 'active' && <Table.HeaderCell textAlign="center">Best SQ</Table.HeaderCell>}
						{showFirstLift('bench', entrants, liveStatus) && <Table.HeaderCell textAlign="center">BP 1</Table.HeaderCell>}
						{liveStatus &&
							((liveStatus.status === 'done' && entrants.find(entrant => entrant['bench1'])) ||
								(liveStatus.status === 'active' && liveStatus.lift === 'bench')) && (
								<Table.HeaderCell textAlign="center">BP 2</Table.HeaderCell>
							)}
						{liveStatus &&
							((liveStatus.status === 'done' && entrants.find(entrant => entrant['bench1'])) ||
								(liveStatus.status === 'active' && liveStatus.lift === 'bench')) && (
								<Table.HeaderCell textAlign="center">BP 3</Table.HeaderCell>
							)}
						{fourthAttempts &&
							liveStatus &&
							((liveStatus.status === 'done' && entrants.find(entrant => entrant['bench4'])) ||
								(liveStatus.status === 'active' && liveStatus.lift === 'bench')) && (
								<Table.HeaderCell textAlign="center">BP 4</Table.HeaderCell>
							)}
						{liveStatus && liveStatus.status === 'active' && liveStatus.lift !== 'squat' && (
							<Table.HeaderCell textAlign="center">Best BP</Table.HeaderCell>
						)}
						{liveStatus && liveStatus.status === 'active' && liveStatus.lift !== 'squat' && (
							<Table.HeaderCell textAlign="center">Sub Total</Table.HeaderCell>
						)}
						{showFirstLift('deadlift', entrants, liveStatus) && <Table.HeaderCell textAlign="center">DL 1</Table.HeaderCell>}
						{liveStatus && liveStatus.lift === 'deadlift' && <Table.HeaderCell textAlign="center">DL 2</Table.HeaderCell>}
						{liveStatus && liveStatus.lift === 'deadlift' && <Table.HeaderCell textAlign="center">DL 3</Table.HeaderCell>}
						{fourthAttempts &&
							liveStatus &&
							((liveStatus.status === 'done' && entrants.find(entrant => entrant['deadlift4'])) ||
								(liveStatus.status === 'active' && liveStatus.lift === 'deadlift')) && (
								<Table.HeaderCell textAlign="center">DL 4</Table.HeaderCell>
							)}
						{liveStatus && liveStatus.status === 'active' && liveStatus.lift === 'deadlift' && (
							<Table.HeaderCell textAlign="center">Best DL</Table.HeaderCell>
						)}
						{liveStatus && (liveStatus.lift === 'deadlift' || liveStatus.status === 'done') && (
							<Table.HeaderCell textAlign="center">Total</Table.HeaderCell>
						)}
						{liveStatus && liveStatus.status === 'done' && <Table.HeaderCell textAlign="center">Result</Table.HeaderCell>}
						<Table.HeaderCell textAlign="center">Events</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{entrants &&
						entrants
							.filter(
								entrant =>
									!liveStatus ||
									(entrant.day === this.state.day &&
										(['done', 'ready'].includes(liveStatus.status) ||
											(liveStatus.status === 'active' && entrant.flight === this.state.flight && entrant.day === this.state.day)))
							)
							.map(entrant => {
								return (
									<Table.Row
										key={entrant.id}
										error={entrant.events === 'error'}
										warning={liveStatus && liveStatus.status === 'active' && liveStatus.lifter === entrant.id}>
										{(!liveStatus || liveStatus.status !== 'done') && (
											<Table.Cell textAlign="center">
												{liveStatus ? (
													entrant.flight
												) : (
													<Mutation mutation={ChangeFlightMutation}>
														{(changeFlight, { loading: updating, error }) => (
															<Dropdown
																closeOnChange
																inline
																options={getFlights()}
																value={entrant.flight}
																onChange={async (e, { value }) => {
																	e.preventDefault()
																	await changeFlight({
																		variables: {
																			id: entrant.id,
																			flight: value,
																		},
																	})
																}}
															/>
														)}
													</Mutation>
												)}
											</Table.Cell>
										)}
										<Table.Cell
											singleLine
											onClick={e => {
												e.preventDefault()
												this.props.openEntrantModal(entrant)
											}}>
											<div style={{ padding: 0, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{entrant.name}</div>
										</Table.Cell>
										<Table.Cell textAlign="center">{entrant.age}</Table.Cell>
										<Table.Cell textAlign="center" singleLine>
											{entrant.division}
										</Table.Cell>
										<Table.Cell textAlign="center">{entrant.weight}</Table.Cell>
										<Table.Cell textAlign="center">{entrant.weightClass}</Table.Cell>
										{showFirstLift('squat', entrants, liveStatus) && (
											<AttemptCell attempt={1} entrant={entrant} event={event} lift="squat" />
										)}
										{liveStatus &&
											((liveStatus.status === 'done' && entrants.find(entrant => entrant['squat1'])) ||
												(liveStatus.status === 'active' && liveStatus.lift === 'squat')) && (
												<AttemptCell
													attempt={2}
													entrant={entrant}
													event={event}
													handleEditableCellExit={this.handleEditableCellExit}
													lift="squat"
													editing={editingCell && editingCell.entrant === entrant.id && editingCell.cellType === 'squat2'}
													setEditableCell={this.setEditableCell}
												/>
											)}
										{liveStatus &&
											((liveStatus.status === 'done' && entrants.find(entrant => entrant['squat1'])) ||
												(liveStatus.status === 'active' && liveStatus.lift === 'squat')) && (
												<AttemptCell
													attempt={3}
													entrant={entrant}
													event={event}
													handleEditableCellExit={this.handleEditableCellExit}
													lift="squat"
													editing={editingCell && editingCell.entrant === entrant.id && editingCell.cellType === 'squat3'}
													setEditableCell={this.setEditableCell}
												/>
											)}
										{fourthAttempts &&
											liveStatus &&
											((liveStatus.status === 'done' && entrants.find(entrant => entrant['squat4'])) ||
												(liveStatus.status === 'active' && liveStatus.lift === 'squat')) && (
												<AttemptCell
													attempt={4}
													entrant={entrant}
													event={event}
													handleEditableCellExit={this.handleEditableCellExit}
													lift="squat"
													editing={editingCell && editingCell.entrant === entrant.id && editingCell.cellType === 'squat4'}
													setEditableCell={this.setEditableCell}
												/>
											)}
										{liveStatus && liveStatus.status === 'active' && <Table.Cell textAlign="center">{getBestSquat(entrant)}</Table.Cell>}
										{showFirstLift('bench', entrants, liveStatus) && (
											<AttemptCell textAlign="center" attempt={1} entrant={entrant} event={event} lift="bench" />
										)}
										{liveStatus &&
											((liveStatus.status === 'done' && entrants.find(entrant => entrant['bench1'])) ||
												(liveStatus.status === 'active' && liveStatus.lift === 'bench')) && (
												<AttemptCell
													attempt={2}
													entrant={entrant}
													event={event}
													handleEditableCellExit={this.handleEditableCellExit}
													lift="bench"
													editing={editingCell && editingCell.entrant === entrant.id && editingCell.cellType === 'bench2'}
													setEditableCell={this.setEditableCell}
												/>
											)}
										{liveStatus &&
											((liveStatus.status === 'done' && entrants.find(entrant => entrant['bench1'])) ||
												(liveStatus.status === 'active' && liveStatus.lift === 'bench')) && (
												<AttemptCell
													attempt={3}
													entrant={entrant}
													event={event}
													handleEditableCellExit={this.handleEditableCellExit}
													lift="bench"
													editing={editingCell && editingCell.entrant === entrant.id && editingCell.cellType === 'bench3'}
													setEditableCell={this.setEditableCell}
												/>
											)}
										{fourthAttempts &&
											liveStatus &&
											((liveStatus.status === 'done' && entrants.find(entrant => entrant['bench4'])) ||
												(liveStatus.status === 'active' && liveStatus.lift === 'bench')) && (
												<AttemptCell
													attempt={4}
													entrant={entrant}
													event={event}
													handleEditableCellExit={this.handleEditableCellExit}
													lift="bench"
													editing={editingCell && editingCell.entrant === entrant.id && editingCell.cellType === 'bench4'}
													setEditableCell={this.setEditableCell}
												/>
											)}
										{liveStatus && liveStatus.status === 'active' && liveStatus.lift !== 'squat' && (
											<Table.Cell textAlign="center">{getBestBench(entrant)}</Table.Cell>
										)}
										{liveStatus && liveStatus.status === 'active' && liveStatus.lift !== 'squat' && (
											<Table.Cell textAlign="center">{getSubTotal(entrant)}</Table.Cell>
										)}
										{showFirstLift('deadlift', entrants, liveStatus) && (
											<AttemptCell attempt={1} entrant={entrant} event={event} lift="deadlift" />
										)}
										{liveStatus &&
											((liveStatus.status === 'done' && entrants.find(entrant => entrant['deadlift1'])) ||
												(liveStatus.status === 'active' && liveStatus.lift === 'deadlift')) && (
												<AttemptCell
													attempt={2}
													entrant={entrant}
													event={event}
													handleEditableCellExit={this.handleEditableCellExit}
													lift="deadlift"
													editing={editingCell && editingCell.entrant === entrant.id && editingCell.cellType === 'deadlift2'}
													setEditableCell={this.setEditableCell}
												/>
											)}
										{liveStatus &&
											((liveStatus.status === 'done' && entrants.find(entrant => entrant['deadlift1'])) ||
												(liveStatus.status === 'active' && liveStatus.lift === 'deadlift')) && (
												<AttemptCell
													attempt={3}
													entrant={entrant}
													event={event}
													handleEditableCellExit={this.handleEditableCellExit}
													lift="deadlift"
													editing={editingCell && editingCell.entrant === entrant.id && editingCell.cellType === 'deadlift3'}
													setEditableCell={this.setEditableCell}
												/>
											)}
										{fourthAttempts &&
											liveStatus &&
											((liveStatus.status === 'done' && entrants.find(entrant => entrant['deadlift4'])) ||
												(liveStatus.status === 'active' && liveStatus.lift === 'deadlift')) && (
												<AttemptCell
													attempt={4}
													entrant={entrant}
													event={event}
													handleEditableCellExit={this.handleEditableCellExit}
													lift="deadlift"
													editing={editingCell && editingCell.entrant === entrant.id && editingCell.cellType === 'deadlift4'}
													setEditableCell={this.setEditableCell}
												/>
											)}
										{liveStatus && liveStatus.status === 'active' && liveStatus.lift === 'deadlift' && (
											<Table.Cell textAlign="center">{getBestDeadlift(entrant)}</Table.Cell>
										)}
										{liveStatus && (liveStatus.status === 'done' || (liveStatus.status === 'active' && liveStatus.lift === 'deadlift')) && (
											<Table.Cell textAlign="center">{getTotal(entrant)}</Table.Cell>
										)}
										{liveStatus && liveStatus.status === 'done' && (
											<Table.Cell textAlign="center" singleLine>
												{getResult(entrant, entrants, event)}
											</Table.Cell>
										)}
										<Table.Cell textAlign="center">{getEventsMapping(entrant.events)}</Table.Cell>
									</Table.Row>
								)
							})}
				</Table.Body>

				{liveStatus && liveStatus.status === 'active' && flights.length > 1 && (
					<Table.Footer>
						<Table.Row>
							<Table.HeaderCell colSpan="30">
								<Menu floated="right" pagination>
									{flights.map(flight => {
										return (
											<Menu.Item
												key={flight}
												active={flight === this.state.flight}
												onClick={async e => {
													e.preventDefault()
													this.setCurrentFlight(flight)
												}}>
												{flight}
											</Menu.Item>
										)
									})}
								</Menu>
							</Table.HeaderCell>
						</Table.Row>
					</Table.Footer>
				)}
			</Table>
		)
	}
}
